import React, { Component,useRef } from "react";
import * as XLSX from "xlsx";
import "./excel.css";
import "./modal.css";
import { Crud_Catalogos, Crud_error } from "../Crud_Catalogos";
import { Dimmer } from "tabler-react";
import { NotificationManager } from "react-notifications";
import { Tab, Tabs, TabList, TabPanel } from "./tabs-generic/index";
import TablaCatalogo from "./tabla-generic/index";
import Modal from 'react-modal';
import { LoadingScreen } from "../../components/LoadingScreen";
import LoadingProgress from 'react-js-loading-progress-bar';
import { forEach } from "jszip";

var id = 0;
var terrores = 0;
let sinError = 0;
var dia = 0;
var mes = 0;
var anio = 0;
var hora = 0;
var minuto = 0;
var segundo = 0;

var fechas = 0;
var horas = 0;
var arrayErr = [];
let Errores = [];
let FilaErr = [];
let Cuadros = [];
let TabTemp = [];
let TabPanelTemp = [];

const fdatetime = "yyyy-mm-dd HH:mm:ss";
const fdate = "yyyy-mm-dd";
const fhora = "HH:mm:ss";
const nobis = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const sbiss = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


 
function bisiesto(year) {
  return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}


function serieMes(numero, año) {
  var cuenta = 0;
  var siguiente = 0;
  var index = 0;
  cuenta = numero;
  do {
    if (bisiesto(año)) {
      siguiente = sbiss[index + 1];
      cuenta = cuenta - sbiss[index];
      dia = cuenta;
    } else {
      siguiente = nobis[index + 1];
      cuenta = cuenta - nobis[index];
      dia = cuenta;
    }
    index = index + 1;
  } while (cuenta > siguiente);
  mes = index + 1;
  dia = cuenta - 1;
}
function serieAnio(valor) {
  var año = 1900;
  var sumadias = 0;
  var residuo = 0;
  do {
    if (bisiesto(año)) {
      año = año + 1;
      sumadias = sumadias + 366;
    } else {
      año = año + 1;
      sumadias = sumadias + 365;
    }
  } while (sumadias + 365 < valor);
  residuo = valor - sumadias;
  anio = año;
  serieMes(residuo, año);
}
function serieHoras(valor) {
  var temporal = 0;
  var residuo = 0;
  temporal = valor;

  temporal = temporal * 24;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  hora = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  minuto = parseInt(temporal, 10);

  temporal = residuo;
  temporal = temporal * 60;
  residuo = Number.parseFloat(temporal) - parseInt(temporal, 10);
  segundo = parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarNJson extends Component {
  constructor(props) {
    super(props);
    this.state = {


      read:false,
      total:null,
      current:null,
      envio:false,
      arrayFailItems:[],
      newData:[],
      arrayCheckedState:[],
      modalDatesOpen:false,
      checkDates:[],
      arrayDates:[],
      countData:0,
      isloading:false,
      modalIsOpen: false,
      catalogo: this.props.catalogo | "",
      movsClientesPendientes :[],
      movsTransportPendientes : [],
      movsDuplicados:[],

      //Catalogo Funcionalidades
      bloque: this.props.bloque | [],

      //Catalogo Funcionalidades
      categorias: this.props.categorias | [],

      //Tabla Movimientos
      idPlataforma: this.props.idPlataforma | [],
      idCliente: this.props.idCliente | [],
      idCorredor: this.props.idCorredor | [],
      idLocalidad: this.props.idLocalidad | [],
      idMovimientoTipo: this.props.idMovimientoTipo | [],
      idNaviera: this.props.idNaviera | [],
      idOperacionTipo: this.props.idOperacionTipo | [],
      idOtd: this.props.idOtd | [],
      idRuta: this.props.idRuta | [],
      idTransportista: this.props.idTransportista | [],
      operacionesEstatus: this.props.operacionesEstatus | [],

      //movimientos:this.props.movimientos							|[],
      //privilegios:this.props.privilegios							|[],

      //Variables
      accion: this.props.accion,
      finalCharge:true,
      validado: this.props.validado | false,

      //Variables de Datos
      dataOld:this.props.data | [],
      data: this.props.data | [],
      dataOtd: this.props.dataOtd | [],
      dataNavieras: this.props.dataNavieras | [],
      dataPlataformas: this.props.dataPlataformas | [],
      dataOperacionesTipo: this.props.dataOperacionesTipo | [],
      dataOperacionesEstatus: this.props.dataOperacionesEstatus | [],
      dataRuta: this.props.dataRuta | [],
      dataMovimientoTipo: this.props.dataMovimientoTipo | [],
      dataTransportistas: this.props.dataTransportistas | [],
      dataCorredores: this.props.dataCorredores | [],
      dataCliente: this.props.dataCliente | [],
      dataContenedorTipo: this.props.dataContenedorTipo | [],
      dataNavieraMovimientos: this.props.dataNavieraMovimientos | [],
    };
    this.cita = 0;
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      plataformaTipo: 1,
    };
    this.contador = 0;    
    this.localidades = {};
    this.localidadesMaersk = {};
    this.clientesCitaAbierta = [];
    this.openModal = this.openModal.bind(this);

    //Se crea catalogo interno de regla de negocio de servicios con fecha abierta para colocacion 
    //de gps 2 dias antes de cita programada.
    this.catTerminalInicio = ["MANZANILLO","LAZARO CARDENAS"];
    this.catDestinoColocacionGps = ["MONTERREY","REYNOSA","CIENEGA",
                                       "RAMOS ARIZPE","CHIHUAHUA","TORREON",
                                       " CIUDAD JUAREZ","PESQUERIA","DURANGO"];
    //this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    this.myModalN("none");
    
  }

  componentDidUpdate(){

  
    
  }
  componentWillMount() {
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        Crud_Catalogos("localidades", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            let localidadesTemp = {};
            returnVal.map((l, index) => {
              localidadesTemp[l.id] = l;
              localidadesTemp[l.nombre] = l;
            });
            this.localidades = localidadesTemp;
          })
          .catch((err) => {});

          Crud_Catalogos("localidades/maersk", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
            
            this.localidadesMaersk = returnVal;
          })
          .catch((err) => {});

          Crud_Catalogos("catalogoFechaAbierta", "", "get", "", [], "", "", "", [])
          .then((returnVal) => {
          
            if(returnVal){
              returnVal.forEach((item)=>{
                this.clientesCitaAbierta.push(item.nombre);
              })
            } 
           
          })
          .catch((err) => {});
    
        break;
      default:
        //console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }

    if(this.state.validado){
      this.setState({isloading:false});
    }

   
  }


  //Inicio Tabla Movimientos
  cargaIdOperacionesEstatus() {
    Crud_Catalogos("operacionesEstatus", "", "get", "", [], "", "", "", [])
      .then((returnVal) => {
        this.setState({ operacionesEstatus: returnVal });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          console.log(
            "Error al Cargar el Catalogo:" +
              this.state.catalogo +
              " verifique el Nombre del Catalogo"
          );
        }
      });
  }
  cargaMovimientos() {}
  //Fin Tabla Movimientos
  async valObjeto(ObjValidar, j,dataItem) {
  
    console.log("enviar",j)
   

    ObjValidar.json = true;
    //{"plataforma":{"id": 2,"nombre":null},"cliente":{"id": 12,"nombre":null},"corredor":{"id": 70,"nombre":null},"movimientoTipo":{"id":1,"nombre":null},"naviera":{"id":8,"nombre":null},"operacionTipo":{"id": 3,"nombre":null},"otd":{"id":1,"nombre":null},"rutasEstatus":{"id":2,"nombre":null},"transportista":{"id":10,"nombre":null},"operacionesEstatus":{"id": 1,"nombre":null} }
    //Crud_Catalogos("validacionDatos/terrestre",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
    
    
      
       await Crud_Catalogos(
          "validacionDatos/naviera",
          "",
          "post",
          "",
          ObjValidar,
          "",
          "",
          "",
          [],
          "",
          "",
          ""
        )
          .then((returnVal) => {
     
           
            dataItem.idPlataforma = returnVal.plataforma;
            dataItem.idCliente = returnVal.cliente;
            dataItem.idCorredor = returnVal.corredor;
            dataItem.idMovimientoTipo = returnVal.movimientoTipo;
            dataItem.idNaviera = returnVal.naviera;
            dataItem.idOperacionTipo = returnVal.operacionTipo;
            dataItem.idOtd = returnVal.otd;
            dataItem.idRuta = returnVal.rutasEstatus;
            dataItem.idTransportista = returnVal.transportista;
            dataItem.idOperacionEstatus = returnVal.operacionEstatus;
            dataItem.po = returnVal.po != null ? returnVal.po.po : null;
            
            this.setState({ current: this.state.current + 1});
            if(returnVal.error != null){
              console.log("entro  errror")
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) Hubo un error al validar " +returnVal.error+ " en la BD, Verifique el JSON con Wo: " +
                (dataItem.WO);
              
              Errores.push(
                <div>
                  {"N) Hubo un error al validar " +returnVal.error+ " en la BD, Verifique el JSON con Wo: " +
                    dataItem.WO}
                </div>
              );
              this.contador = this.contador +1;
              return;
            }

    
            if(returnVal.po?.existe){  
    
              this.setState(prevState => ({
                movsDuplicados: [...prevState.movsDuplicados, dataItem.WO]
              }))
    
    
              dataItem.id = returnVal.id; 
              dataItem.destino = returnVal.destino ? returnVal.destino : dataItem.destino; 
                    
            
            }
    
            
    
            let tempplataforma = returnVal.plataforma; //dataItem.idPlataforma;
            if (tempplataforma == null ||
              tempplataforma.nombre === "" ||
              tempplataforma.nombre === null ||
              tempplataforma.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Plataforma No Existe! en la BD, Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Plataforma No Existe! en la BD, Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
            if (tempplataforma?.id === null || tempplataforma?.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Plataforma No Existe! en la BD, Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El Plataforma No Existe! en la BD, Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let tempcliente = returnVal.cliente; //dataItem.idCliente;
            if (tempcliente == null ||
              tempcliente.nombre === "" ||
              tempcliente.nombre === null ||
              tempcliente.nombre === undefined
            ) {
    
              //console.log(tempcliente)
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) El Cliente NO Está VINCULADO a la Plataforma -" +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) El Cliente NO Está VINCULADO a la Plataforma -" +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }else{
    
             
                if(tempcliente.nombre.includes("Pendiente")){
    
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + j;
                  arrayErr[terrores] =
                    "(N) El cliente NO Está VINCULADO a la Plataforma " +
                    tempcliente.nombre+
                    ", Verifique en JSON la Fila: " +
                    (j + 1);
                  
                  Errores.push(
                    <div>
                      {"(N) El cliente NO Está VINCULADO a la Plataforma " +
                        ObjValidar.cliente.nombre +
                        ", Verifique en JSON la Fila con wo: " +
                        (dataItem.WO)}
                    </div>
                  );
    
                //   this.setState(prevState => ({
                //     movsClientesPendientes: [...prevState.movsClientesPendientes, dataItem.WO]
                //   }))
                   
                }
    
            }
            if (tempcliente == null || tempcliente.id === null || tempcliente.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Cliente NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El Cliente NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let tempcorredor = returnVal.corredor; //dataItem.idCorredor;
            if (tempcorredor == null ||
              tempcorredor.nombre === "" ||
              tempcorredor.nombre === null ||
              tempcorredor.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Corredor NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON el servicio con WO: " +
                (dataItem.WO);
              
              Errores.push(
                <div>
                  {"(N) La Corredor NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ",Verifique en JSON el servicio con WO:  " +
                    (dataItem.WO)}
                </div>
              );
            }
    
            let localidad ='';
            if(dataItem.Direction == "Exportación"){
              console.log("entro export")
              localidad =  dataItem['Begin Location Name'];
            }else{
              localidad = dataItem.Region.replace("MEXICO VIA ","");
            }
        
            if (!dataItem.idLocalidad || !this.localidades[dataItem.idLocalidad.nombre] ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) No se han encontrado coincidencias para la localidad " + localidad+
                ", Verifique el Json con wo: " +
                (dataItem.WO);
              
              Errores.push(
                <div>
                  {"(N) No se han encontrado coincidencias para la localidad " +localidad+
                    ", Verifique en el Json con wo: " +
                    (dataItem.WO)}
                </div>
              );
            }
    
            if (tempcorredor == null || tempcorredor.id === null || tempcorredor.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Corredor NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (dataItem.WO);
             
              Errores.push(
                <div>
                  {"(I) El Corredor "+tempcorredor?.nombre+ " NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila con wo: " +
                    (dataItem.WO)}
                </div>
              );
            }
    
            let tempmovimientotipo = returnVal.movimientoTipo; //dataItem.idMovimientoTipo;
            if (tempmovimientotipo == null ||
              tempmovimientotipo.nombre === "" ||
              tempmovimientotipo.nombre === null ||
              tempmovimientotipo.nombre === undefined
            ) {
              
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
            if (tempmovimientotipo == null || tempmovimientotipo.id === null || tempmovimientotipo.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El Tipo de Movimiento NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let tempnaviera = returnVal.naviera; //dataItem.idNaviera;
            if ( tempnaviera == null ||
              tempnaviera.nombre === "" ||
              tempnaviera.nombre === null ||
              tempnaviera.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Naviera NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
            if (tempnaviera == null || tempnaviera.id === null || tempnaviera.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) La Naviera NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) La Naviera NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let tempoperaciontipo = returnVal.operacionTipo; //dataItem.idOperacionTipo;
            if (tempoperaciontipo == null ||
              tempoperaciontipo.nombre === "" ||
              tempoperaciontipo.nombre === null ||
              tempoperaciontipo.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Operacion Tipo NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila con wo: " +
                    (dataItem.WO)}
                </div>
              );
            }
            if (tempoperaciontipo == null || tempoperaciontipo.id === null || tempoperaciontipo.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila con wo: " +
                (dataItem.WO);
    
              
              Errores.push(
                <div>
                  {"(I) El Tipo de Operación NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila con wo: " +
                    (dataItem.WO)}
                </div>
              );
    
            }
    
            let tempotd = returnVal.otd; //dataItem.idOtd;
            if (tempotd == null ||
              tempotd.nombre === "" ||
              tempotd.nombre === null ||
              tempotd.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Otd NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Otd NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
            if (tempotd == null || tempotd.id === null || tempotd.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El OTD NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El OTD NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let temprutasestatus = returnVal.rutasEstatus; //dataItem.idRuta;
            if (temprutasestatus == null ||
              temprutasestatus.nombre === "" ||
              temprutasestatus.nombre === null ||
              temprutasestatus.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
            if (temprutasestatus == null || temprutasestatus.id === null || temprutasestatus.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) La Ruta Estatus NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let temptransportista = returnVal.transportista; //dataItem.idTransportista;
            if (temptransportista == null  ||
              temptransportista.nombre === "" ||
              temptransportista.nombre === null ||
              temptransportista.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }else{
    
    
              if(temptransportista.nombre.includes("Pendiente")){
    
              //   this.setState(prevState => ({
              //     movsTransportPendientes: [...prevState.movsTransportPendientes, dataItem.WO]
              //   }))
    
    
    
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) El Transportista NO Está VINCULADO a la Plataforma " +
                temptransportista.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                    ObjValidar.transportista.nombre +
                    ", Verifique en JSON la Fila con wo: " +
                    (dataItem.WO)}
                </div>
              );
                 
              }
            }
            if (temptransportista == null || temptransportista.id === null || temptransportista.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
    
            let tempoperacionEstatus = returnVal.operacionEstatus; //dataItem.idTransportista;
            if (tempoperacionEstatus == null ||
              tempoperacionEstatus.nombre === "" ||
              tempoperacionEstatus.nombre === null ||
              tempoperacionEstatus.nombre === undefined
            ) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(N) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(N) El Transportista NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
            }
          if (tempoperacionEstatus == null || tempoperacionEstatus.id === null || tempoperacionEstatus.id === 0) {
              terrores = terrores + 1;
              FilaErr[j] = "Error en la Fila " + j;
              arrayErr[terrores] =
                "(I) El Transportista NO Está VINCULADO a la Plataforma " +
                tempplataforma?.nombre +
                ", Verifique en JSON la Fila: " +
                (j + 1);
              
              Errores.push(
                <div>
                  {"(I) El Transportista NO Está VINCULADO a la Plataforma " +
                    tempplataforma?.nombre +
                    ", Verifique en JSON la Fila: " +
                    (j + 1)}
                </div>
              );
    
              
            }
            this.contador = this.contador +1;
            
            if(this.state.data.length == this.contador ){
             
              this.setState({validado:true})
              this.setState({isloading:false});
              this.setState({total:0})
              this.setState({current:0})
              this.setState({arrayDates:[]})
              this.setState({arrayCheckedState:[]})
              this.myModalN('block')
            }

           

            
            
          }).catch((err) => {
            console.log("343 Error de validacionDatos: " + err);
          });
         

      
        
    
      
  }
  
  valCatalogoId(Catalogo, Id, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.id = Id;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.id = Id; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.id = Id; //this.state.corredores[I].id;
        this.state.data[I].idLocalidad = Campo;
        break;
      case "Direction":
        Campo.id = Id; //this.state.movimientosTipo[I].id;
        this.state.data[I].movimientosTipo = Campo;
        break;
      case "idNaviera":
        Campo.id = Id; //this.state.navieras[I].id;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.id = Id; //this.state.operacionesTipo[I].id;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.id = Id; //this.state.otd[I].id;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.id = Id; //this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.id = Id; //this.state.transportistas[I].id;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.id = Id; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo; //this.state.operacionesEstatus[k];
        break;

      case "po":
        Campo.po = Id; //this.state.transportistas[I].id;
        this.state.data[I].po = Id;
        break;
      case "citaProgramada":
        this.state.data[I].citaProgramada = Campo;
        break;
      case "citaColocacionGps":
        this.state.data[I].citaColocacionGps = Campo;
        break;
      case "arriboTerminalVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio = Campo;
        break;
      case "salidaTerminalLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaTerminalLleno = Campo;
        break;
      case "arriboClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteLleno = Campo;
        break;
      case "salidaClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteVacio = Campo;
        break;
      case "arriboTerminalVacio2":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalVacio2 = Campo;
        break;
      case "retiroContenedor":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas); //datos[num]= ""+anio+"-"+mes+"-"+dia+""
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].retiroContenedor = Campo;
        break;
      case "arriboClienteVacio":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboClienteVacio = Campo;
        break;
      case "salidaClienteLleno":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].salidaClienteLleno = Campo;
        break;
      case "arriboTerminalCargado":
        anio = 0;
        mes = 0;
        dia = 0;
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieAnio(fechas);
        serieHoras(horas);
        Campo =
          "" +
          anio +
          "-" +
          mes +
          "-" +
          dia +
          " " +
          hora +
          ":" +
          minuto +
          ":" +
          segundo +
          "";
        this.state.data[I].arriboTerminalCargado = Campo;
        break;

      case "eta":
        //fechas = parseInt(Id, 10);
        //horas = Number.parseFloat(Id)-fechas;
        //serieHoras(horas);
        //Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].eta = Campo;
        break;
      case "delay":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].delay = Campo;
        break;
      case "wti6h":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].wti6h = Campo;
        break;
      case "transit":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].transit = Campo;
        break;
      case "ontime":
        fechas = parseInt(Id, 10);
        horas = Number.parseFloat(Id) - fechas;
        serieHoras(horas);
        Campo = "" + hora + ":" + minuto + ":" + segundo + "";
        this.state.data[I].ontime = Campo;
        break;

      default:
        console.log("No Existe el Catalogo Definido");
        break;
    }
  }
  getCatalogoId(Catalogo, Nombre, I, Campo) {
    var k = 0;
    switch (Catalogo) {
      case "idPlataforma":
        Campo.nombre = Nombre;
        this.state.data[I].idPlataforma = Campo;
        break;
      case "idCliente":
        Campo.nombre = Nombre; //this.state.clientes[I].id;
        this.state.data[I].idCliente = Campo;
        break;
      case "idCorredor":
        Campo.nombre = Nombre;
        
        this.state.data[I].idCorredor = Campo;
        break;
      case "idLocalidad":
        Campo.nombre = Nombre;
        
        this.state.data[I].idLocalidad = this.localidades[Nombre];
        break;
      case "idMovimientoTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idMovimientoTipo = Campo;
        break;
      case "idNaviera":
        Campo.nombre = Nombre;
        this.state.data[I].idNaviera = Campo;
        break;
      case "idOperacionTipo":
        Campo.nombre = Nombre;
        this.state.data[I].idOperacionTipo = Campo;
        break;
      case "idOtd":
        Campo.nombre = Nombre;
        this.state.data[I].idOtd = Campo;
        break;
      case "idRuta":
        Campo.nombre = Nombre; //Campo.id=this.state.rutasEstatus[I].id;
        this.state.data[I].idRuta = Campo;
        break;
      case "idTransportista":
        Campo.nombre = Nombre;
        this.state.data[I].idTransportista = Campo;
        break;
      case "idOperacionEstatus":
        Campo.nombre = Nombre; //this.state.operacionesEstatus[I].id;
        this.state.data[I].idOperacionEstatus = Campo;
        break;

      case "po":
        Campo.po = Nombre; //this.state.transportistas[I].id;
        this.state.data[I].po = Nombre;
        break;
      default:
        NotificationManager.warning("No Existe el Catalogo Definido");
    }
  }

   ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.00042;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    let datenew =  new Date(date_info.getFullYear(), date_info.getDate(),date_info.getMonth() + 1 , hours, minutes, seconds);
 
    return this.convertDateString(datenew)
 }


  convertDateString = (converted_date) => {


    let minutes = converted_date.getMinutes().toString().length <=1 ? 
    '0' + converted_date.getMinutes() : converted_date.getMinutes()
    
    let hours = converted_date.getHours().toString().length <=1 ? '0' + converted_date.getHours() : 
    converted_date.getHours() 
    let time =  hours + ":"  +  minutes

    converted_date = String(converted_date).slice(4, 15)
   let  date = converted_date.split(" ")
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
    if (month.toString().length <= 1)
        month = '0' + month
    let year = date[2];
    return String(day + '-' + month + '-' + year.slice(0, 4) + " " + time)
}

  seleccionaFechas(){

    this.state.data.map((datos) => {

    if(!isNaN(datos['Order Deadline'])){
      console.log(datos['Order Deadline'])
      const date = this.ExcelDateToJSDate(datos['Order Deadline'])
      datos['Order Deadline'] = date;

      console.log(date)
    }
    datos['Order Deadline']=  datos['Order Deadline'].replaceAll('/','-')

    const [d,M,y,h,m] = datos['Order Deadline'].split(/[- :]/);
    const fecha_utc =  new Date(y,parseInt(M)-1,d,(h)-6,parseInt(m)); 
    

    datos["Order Deadline"] =  fecha_utc.toISOString().replace('T', ' ');
    datos["Order Deadline"] =  datos["Order Deadline"].replace(".000Z","")
    
    if(this.state.arrayDates.findIndex((date) => date == datos["Order Deadline"].split(" ")[0]) == -1){

      this.state.arrayDates.push(datos["Order Deadline"].split(" ")[0])
      

    }

    });

    let fill  =new Array(this.state.arrayDates.length).fill(false);

    this.setState({arrayCheckedState:fill})

   
    this.setState({modalDatesOpen:true})
  }

 async updateData(event){

    let newData = [];
    let newdate;
    let tempArrayInputs = [];
    let tempArray = []

    console.log(this.state.arrayCheckedState)

    this.state.arrayDates.forEach(element => {
      let datearray = element.split("-");
                      tempArrayInputs.push(datearray[2] + '-' + datearray[1] + '-' + datearray[0]);
    });
        
  
    tempArrayInputs.sort(function(a,b){
      return new Date(b) - new Date(a);
    });

    if(this.state.arrayCheckedState.length > 0){

      tempArrayInputs.forEach((date,index)=>{
        let datearray2 = date.split("-");
        newdate = (datearray2[2] + '-' + datearray2[1] + '-' + datearray2[0]);
        if(this.state.arrayCheckedState[index]){
          newData.push(this.state.data.filter((item)=> { 

             /*Se setea el nombre del cliente en caso de que venga nulo*/
            if(item['Customer Name'] == null){
               item['Customer Name'] = item['End Addressline2']; this.setState({ arrayFailItems: [...this.state.arrayFailItems, item] }); 
            } 

            
            return item['Order Deadline'].split(" ")[0] == newdate
          })
          )
         
        }
      })

    }

    newData.forEach(element => {
      tempArray = (tempArray.concat(element));
    });

    console.log(tempArray)

    TabPanelTemp.push(
      <TabPanel>
        <TablaCatalogo
          catalogo={"navieraMovimientos"}
          contenido={tempArray}
        />
      </TabPanel>
    );

   await this.setState({data:tempArray});
    this.setState({modalDatesOpen:false});
    this.validaArchivo(tempArray);


  }

  async validaArchivo() {
   
    this.setState({isloading:true});
    this.myModalN("block")

    console.log(this.state.data)
    //console.log("Inicia Validacion de Archivo Naviero");
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        var j = 0;
      for (const datos of this.state.data) {
          
          var plataforma = { id: "", nombre: "" };
          var cliente = { id: "", nombre: "" ,latitud:"",longitud:"",nombreCorto:""};
          var corredor = { id: "", nombre: "" };
          var localidad = { id: "", localidad: "" };
          var movimientoTipo = { id: "", nombre: "" };
          var naviera = { id: "", nombre: "" };
          var operacionTipo = { id: "", nombre: "" };
          var otd = { id: "", nombre: "" };
          var rutasEstatus = { id: "", nombre: "" }; //rutasEstatus
          var transportista = { id: "", nombre: "" };
          var operacionEstatus = { id: "", nombre: "" };
          var po = { id: "", existe: false };
          var serialTransportista = null;
          var objeto = {
            plataforma,
            cliente,
            corredor,
            movimientoTipo,
            naviera,
            operacionTipo,
            otd,
            rutasEstatus,
            transportista,
            operacionEstatus,
            po,
            localidad,
            serialTransportista
          };

          datos["idPlataforma"] = datos["Plataforma"] ? datos["Plataforma"] : "MAERSK";
          datos["idOperacionEstatus"] = "SIN GPS";
          datos["idRuta"] = "**UNIDAD PROGRAMADA**";
          datos["idOtd"] = "Sin Código";
          datos["idNaviera"] = datos["Naviera"] ? datos["Naviera"] :  "Maersk Line";
          datos["idOperacionTipo"] = datos["OperationType"] ? datos["OperationType"] :  "FIRST MILE";
          datos["vip"] = true;
          datos["booking"] = datos["BL"].toString().split("-")[0];
          datos["contenedor"] = datos["Container No"];
          datos["contenedorSize"] = datos["Size"];
          datos["po"] = datos["WO"];
          datos["destino"] = datos["End Location Name"];
          datos["Type"] == "HREF" ? datos["contenedorTiponombreMaersk"] = 2 : datos["contenedorTipo"] = 1


          if(datos['Order Deadline'] ){

          
            //Cita programada abierta en base a cliente
            if(this.clientesCitaAbierta.length > 0 ){
              this.clientesCitaAbierta.forEach(element => {
          
                if(datos['Customer Name'] && datos['Customer Name'].includes(element)){
                  datos['Order Deadline'] = datos['Order Deadline'].split(" ")[0] + " 23:59:00"
                }
              
              });
            }


            let dt  = datos['Order Deadline'].split(/\-|\s/)
            const tzoffset = (new Date()).getTimezoneOffset() * 60000;
         
            let dat = new Date(datos['Order Deadline']);
        
            let getTime = dat.getTime() - tzoffset;

            let dia = (24 * 60 * 60 * 1000)
            let res = getTime - dia;
            
            
            // se genera una cita de colocacion de gps 2 dias antes, para estas rutas desde 
            this.catTerminalInicio.forEach(terminal => {

              // hasta

              if(datos['Begin Location Name'] && datos['Begin Location Name'].includes(terminal)){
              
                this.catDestinoColocacionGps.forEach(destino => {

                if(datos['End Location Name'] && datos['End Location Name'].includes(destino)){
                
                     res -= dia;
                }
              
                });

              }
            });

            

            

            res = new Date(res);
          
            datos["citaColocacionGps"] =  res.toISOString().replace('T', ' ');
            datos["citaColocacionGps"] = datos["citaColocacionGps"].replace(".000Z","")
          
          }

          if(datos["Main Supplier"]){

            objeto.serialTransportista = 1 * datos["Main Supplier"];

          }
         
          if(datos["Direction"] == "Export"){

            if(datos["Begin Location Name"]){

              let foundLocalidad = this.localidadesMaersk.filter((localidad)=> {
                return datos["Begin Location Name"] == (localidad.nombreMaersk)
              })

              
              datos["idLocalidad"] = foundLocalidad.length > 0 ? foundLocalidad[0].nombreOne : datos["Begin Location Name"];
            }
            
          }else{
            
             datos["idLocalidad"] = datos["Region"].replace("MEXICO VIA ","");

            let foundLocalidad = this.localidadesMaersk.filter((localidad)=> {
              return datos["idLocalidad"] == (localidad.nombreMaersk)
            })

            
            datos["idLocalidad"] = foundLocalidad.length > 0 ? foundLocalidad[0].nombreOne : datos["idLocalidad"];
          }


          Object.keys(datos).forEach((num) => {
            
            switch (
              num //Inicia Switch num
            ) {
              case "idPlataforma":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                } else {
                  plataforma.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idPlataforma",
                      datos[num],
                      j,
                      plataforma
                    );
                  }
                }
                break;
              case "Customer Name":
                if (Number.isInteger(datos["Customer Name"])) {
                  {
                    this.valCatalogoId("idCliente", datos["Customer Name"], j, cliente);
                  }
                } else {
                  let nameCustomer = "";
                  if(datos["Customer Name"]){
                    nameCustomer = datos["Customer Name"].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"");
                    nameCustomer = nameCustomer.replace(/  +/g, ' ');

                   
                    nameCustomer = nameCustomer.split(" ");
                    
                  }
                  
                  if(nameCustomer[1]){
                    cliente.nombre = nameCustomer[0] +" "+ nameCustomer[1]+" "+ datos["End Location Name"];
                  }else{
                    cliente.nombre = nameCustomer[0] +" "+ datos["End Location Name"];
                  }
                  
                  
                  if(datos['Customer Name']){    
                    
                    cliente.latitud = datos["End LATITUDE"];
                    cliente.longitud = datos["End LONGITUDE"];
                    cliente.nombreCorto = datos["Customer Name"].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,"").replace(/  +/g, ' ');


                  }
              
                  {
                    this.getCatalogoId("idCliente", cliente.nombre, j, cliente);
                  }
                }
                break;
              case "Region":
                if (Number.isInteger(datos["Region"])) {
                  {
                    this.valCatalogoId("idCorredor", datos["Region"], j, corredor);
                  }
                } else {
                  datos["Region"] = datos["Region"].replace("MEXICO VIA ","")
                 

                  if(datos['Region'] == "TIHL"){
                    datos['Region'] = "TILH"
                  }else

                  if(datos['Region'] && datos['Region'].includes("MEXICO CITY")){
                    
                    datos['Region'] = "PANTACO"
                  }

                  if(datos['Region'] && datos['Region'].includes("PROGRESO")){
                  
                    datos['Region'] = "PROGRESO YUCATAN"
                  }

                  if(datos['Region'] && datos['Region'].includes("CONTRIMODAL")){
                    
                    datos['Region'] = "APM CUAUTITLAN"
                  }

                  
                  corredor.nombre = datos["Region"];
                  
                  this.getCatalogoId("idCorredor", datos["Region"], j, corredor);
                  

                 
                }
                break;
              case "idLocalidad":
                if (Number.isInteger(datos["idLocalidad"])) {
                  {
                    this.valCatalogoId("idLocalidad", datos["idLocalidad"], j, localidad);
                  }
                } else {

                  localidad.nombre = datos["idLocalidad"];
                  
                  this.getCatalogoId("idLocalidad", datos["idLocalidad"], j, localidad);
                }
                break;
              case "Direction":
                if (Number.isInteger(datos["Direction"])) {
                  {
                    this.valCatalogoId(
                      "idMovimientoTipo",
                      datos["Direction"],
                      j,
                      movimientoTipo
                    );
                  }
                } else {

                  switch (
                    datos["Direction"] //Inicia Switch Tipo de movimiento
                  ) {


                    case "Export":
                      datos["Direction"] = "Exportación";
                    break;
                    case "Export Local":
                      datos["Direction"] = "Exportación Local";
                    break;
                    case "Import Local":
                      datos["Direction"] = "Importación Local";
                    break;
                    case "Import":
                      datos["Direction"] = "Importación";
                    break;
                  }
                  

                  console.log(datos["Direction"])
                  movimientoTipo.nombre = datos["Direction"];
                  {
                    this.getCatalogoId(
                      "idMovimientoTipo",
                      datos["Direction"],
                      j,
                      movimientoTipo
                    );
                  }
                }
                break;
              case "idNaviera":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                } else {
                  naviera.nombre = datos[num];
                  {
                    this.getCatalogoId("idNaviera", datos[num], j, naviera);
                  }
                }
                break;
              case "idOperacionTipo":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                } else {
                  operacionTipo.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionTipo",
                      datos[num],
                      j,
                      operacionTipo
                    );
                  }
                }
                break;
              case "idOtd":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idOtd", datos[num], j, otd);
                  }
                } else {
                  otd.nombre = datos[num];
                  {
                    this.getCatalogoId("idOtd", datos[num], j, otd);
                  }
                }
                break;
              case "idRuta":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                } else {
                  rutasEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId("idRuta", datos[num], j, rutasEstatus);
                  }
                }
                break;
              case "Trucking Line":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idTransportista",
                      datos[num],
                      j,
                      transportista
                    );
                  }
                } else {
                  transportista.nombre = datos["Trucking Line"];
                  {
                    this.getCatalogoId(
                      "idTransportista",
                      datos["Trucking Line"],
                      j,
                      transportista
                    );
                  }
                }
                break;
              case "idOperacionEstatus":
                if (Number.isInteger(datos[num])) {
                  {
                    this.valCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                } else {
                  operacionEstatus.nombre = datos[num];
                  {
                    this.getCatalogoId(
                      "idOperacionEstatus",
                      datos[num],
                      j,
                      operacionEstatus
                    );
                  }
                }
                break;
              case "Order Deadline":
                var citaProgramada = "";
                citaProgramada = datos["Order Deadline"]; 
               
               //dataItem.citaProgramada;
                {
                  this.valCatalogoId(
                    "citaProgramada",
                    datos["Order Deadline"],
                    j,
                    citaProgramada
                  );
                }
                if (
                  citaProgramada === 0 ||
                  citaProgramada === null ||
                  citaProgramada === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaProgramada:" +
                    citaProgramada +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j );
                  //NotificationManager.warning("La citaProgramada:" + citaProgramada + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaProgramada:" +
                        citaProgramada +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "citaColocacionGps":
                var citaColocacionGps = "";
                citaColocacionGps = datos[num];
                {
                  this.valCatalogoId(
                    "citaColocacionGps",
                    datos[num],
                    j,
                    citaColocacionGps
                  );
                }
                if (
                  citaColocacionGps === 0 ||
                  citaColocacionGps === null ||
                  citaColocacionGps === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j + 1);
                  arrayErr[terrores] =
                    "La citaColocacionGps:" +
                    citaColocacionGps +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La citaColocacionGps:" + citaColocacionGps + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La citaColocacionGps:" +
                        citaColocacionGps +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio":
                var arriboTerminalVacio = "";
                arriboTerminalVacio = datos[num]; //dataItem.arriboTerminalVacio;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio",
                    datos[num],
                    j,
                    arriboTerminalVacio
                  );
                }
                if (
                  arriboTerminalVacio === 0 ||
                  arriboTerminalVacio === null ||
                  arriboTerminalVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio:" +
                    arriboTerminalVacio +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j + 1);
                  //NotificationManager.warning("La arriboTerminalVacio:" + arriboTerminalVacio + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio:" +
                        arriboTerminalVacio +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "salidaTerminalLleno":
                var salidaTerminalLleno = "";
                salidaTerminalLleno = datos[num]; //dataItem.salidaTerminalLleno;
                {
                  this.valCatalogoId(
                    "salidaTerminalLleno",
                    datos[num],
                    j,
                    salidaTerminalLleno
                  );
                }
                if (
                  salidaTerminalLleno === 0 ||
                  salidaTerminalLleno === null ||
                  salidaTerminalLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaTerminalLleno:" +
                    salidaTerminalLleno +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaTerminalLleno:" + salidaTerminalLleno + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaTerminalLleno:" +
                        salidaTerminalLleno +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j + 1)}
                    </div>
                  );
                }
                break;
              case "arriboClienteLleno":
                var arriboClienteLleno = "";
                arriboClienteLleno = datos[num]; //dataItem.arriboClienteLleno;
                {
                  this.valCatalogoId(
                    "arriboClienteLleno",
                    datos[num],
                    j,
                    arriboClienteLleno
                  );
                }
                if (
                  arriboClienteLleno === 0 ||
                  arriboClienteLleno === null ||
                  arriboClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboClienteLleno:" +
                    arriboClienteLleno +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboClienteLleno:" + arriboClienteLleno + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteLleno:" +
                        arriboClienteLleno +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "salidaClienteVacio":
                var salidaClienteVacio = "";
                salidaClienteVacio = datos[num]; //dataItem.salidaClienteVacio;
                {
                  this.valCatalogoId(
                    "salidaClienteVacio",
                    datos[num],
                    j,
                    salidaClienteVacio
                  );
                }
                if (
                  salidaClienteVacio === 0 ||
                  salidaClienteVacio === null ||
                  salidaClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaClienteVacio:" +
                    salidaClienteVacio +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaClienteVacio:" + salidaClienteVacio + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteVacio:" +
                        salidaClienteVacio +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalVacio2":
                var arriboTerminalVacio2 = "";
                arriboTerminalVacio2 = datos[num]; //dataItem.arriboTerminalVacio2;
                {
                  this.valCatalogoId(
                    "arriboTerminalVacio2",
                    datos[num],
                    j,
                    arriboTerminalVacio2
                  );
                }
                if (
                  arriboTerminalVacio2 === 0 ||
                  arriboTerminalVacio2 === null ||
                  arriboTerminalVacio2 === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalVacio2:" +
                    arriboTerminalVacio2 +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboTerminalVacio2:" + arriboTerminalVacio2 + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalVacio2:" +
                        arriboTerminalVacio2 +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "retiroContenedor":
                var retiroContenedor = "";
                retiroContenedor = datos[num]; //dataItem.retiroContenedor;
                {
                  this.valCatalogoId(
                    "retiroContenedor",
                    datos[num],
                    j,
                    retiroContenedor
                  );
                }
                if (
                  retiroContenedor === 0 ||
                  retiroContenedor === null ||
                  retiroContenedor === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La retiroContenedor:" +
                    retiroContenedor +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La retiroContenedor:" + retiroContenedor + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La retiroContenedor:" +
                        retiroContenedor +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboClienteVacio":
                var arriboClienteVacio = "";
                arriboClienteVacio = datos[num]; //dataItem.arriboClienteVacio;
                {
                  this.valCatalogoId(
                    "arriboClienteVacio",
                    datos[num],
                    j,
                    arriboClienteVacio
                  );
                }
                if (
                  arriboClienteVacio === 0 ||
                  arriboClienteVacio === null ||
                  arriboClienteVacio === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboClienteVacio:" +
                    arriboClienteVacio +
                    " No Puede ser Null!, Verifique en JS97641859ON la Fila: " +
                    (j );
                  //NotificationManager.warning("La arriboClienteVacio:" + arriboClienteVacio + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboClienteVacio:" +
                        arriboClienteVacio +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "salidaClienteLleno":
                var salidaClienteLleno = "";
                salidaClienteLleno = datos[num]; //dataItem.salidaClienteLleno;
                {
                  this.valCatalogoId(
                    "salidaClienteLleno",
                    datos[num],
                    j,
                    salidaClienteLleno
                  );
                }
                if (
                  salidaClienteLleno === 0 ||
                  salidaClienteLleno === null ||
                  salidaClienteLleno === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La salidaClienteLleno:" +
                    salidaClienteLleno +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La salidaClienteLleno:" + salidaClienteLleno + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La salidaClienteLleno:" +
                        salidaClienteLleno +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "arriboTerminalCargado":
                var arriboTerminalCargado = "";
                arriboTerminalCargado = datos[num]; //dataItem.arriboTerminalCargado;
                {
                  this.valCatalogoId(
                    "arriboTerminalCargado",
                    datos[num],
                    j,
                    arriboTerminalCargado
                  );
                }
                if (
                  arriboTerminalCargado === 0 ||
                  arriboTerminalCargado === null ||
                  arriboTerminalCargado === ""
                ) {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j);
                  arrayErr[terrores] =
                    "La arriboTerminalCargado:" +
                    arriboTerminalCargado +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j);
                  //NotificationManager.warning("La arriboTerminalCargado:" + arriboTerminalCargado + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"La arriboTerminalCargado:" +
                        arriboTerminalCargado +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j )}
                    </div>
                  );
                }
                break;
              case "wti6h":
                var wti6h = "";
                wti6h = datos[num]; //dataItem.retiroContenedor;
                {
                  this.valCatalogoId("wti6h", datos[num], j, wti6h);
                }
                if (wti6h === 0 || wti6h === null || wti6h === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j );
                  arrayErr[terrores] =
                    "El wti6h:" +
                    wti6h +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j );
                  //NotificationManager.warning("El wti6h:" + wti6h + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El wti6h:" +
                        wti6h +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j )}
                    </div>
                  );
                }
                break;
              case "ontime":
                var ontime = "";
                ontime = datos[num]; //dataItem.ontime;
                {
                  this.valCatalogoId("ontime", datos[num], j, ontime);
                }
                if (ontime === 0 || ontime === null || ontime === "") {
                  terrores = terrores + 1;
                  FilaErr[j] = "Error en la Fila " + (j );
                  arrayErr[terrores] =
                    "El ontime:" +
                    ontime +
                    " No Puede ser Null!, Verifique en JSON la Fila: " +
                    (j );
                  //NotificationManager.warning("El ontime:" + ontime + " No Puede ser Null!, Verifique en JSON la Fila: " + (j+1) );
                  Errores.push(
                    <div>
                      {"El ontime:" +
                        ontime +
                        " No Puede ser Null!, Verifique en JSON la Fila: " +
                        (j)}
                    </div>
                  );
                }
                break;
              case "po":
                //po.po=datos[num];
                  this.valCatalogoId("po", datos["po"], j, po);
                break;
              default:
                break;
            } //Fin Switch num
          });


          if(datos["Customer Name"] == null){
            await this.setState({ arrayFailItems: [...this.state.arrayFailItems, datos] })

          }

            await this.valObjeto(objeto, j,datos)  
            j++;  
            

          
        };

        
        
        //  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
        //terrores=terrores+1;    
      
        Errores.push(<div id={j}>{"Archivo Validado!"}</div>);
       console.log("termino JSON")
        console.log(this.state.arrayFailItems)
        break; //Fin Case Movimientos
      default:
        NotificationManager.warning =
          "No esta Defina una Carga Masiva para este Catalogo";
        break;
    }


    
    //this.setState({validado:true });
    //console.log(this.state.data);
    //console.log("Fin Validacion de Archivo: "+ terrores);
  }


  senData = (carga,errores) =>{
    this.myModalN("none")
    this.setState({total:carga.length})
    
    this.setState({envio:true})
    this.cargarDatos(carga,errores)
    
    this.contador = 0;
  }

async  cargarDatos (carga, errores){

 
    let w = 0;
    let errResponse = [];
    let cargados= 0;
    let duplicados = 0;
    let data = "";
    
    for (let i = 0; i < carga.length; i++) {
      let nDe = 0;
      let nEn = 0;
      if (errores == 0) {

        let dup = this.state.movsDuplicados.filter((movDup) => {
          return movDup == carga[i].po; 
        })


        if(carga[i]["Direction"] == 'Exportación' 
          && (carga[i]["Customer Name"].includes('SAMSUNG SDS') || carga[i]["Customer Name"].includes('SAMSUNG ELECTRONICS') ) && carga[i]["End Location Name"] == 'QUERETARO'){

            console.log("cambio de puerto SAMSUNG SDS" , carga[i])
            if(carga[i]["Region"].includes("LAZARO CARDENAS")){
              carga[i].idCliente = {id:107862 ,nombre:"MSK_SAMSUNG_SDS_EXPO_LAZARO_CARDENAS_107862"}; 
            }

            if(carga[i]["Region"].includes("ALTAMIRA")){
               carga[i].idCliente = {id:107704 ,nombre:"MSK_SAMSUNG_SDS_EXPO_ALTAMIRA_107704"}; 
            }
        
          }
        
        if(dup.length > 0){
          console.log("se duplico")
          
          // delete carga[i].BL
          // delete carga[i].WO
          // delete carga[i].Vendor
          // delete carga[i].Type
          // delete carga[i]["Trucking Line"]
          // delete carga[i].Size
          // delete carga[i].Region
          // delete carga[i].Location
          // delete carga[i].Direction
          // delete carga[i]['Order Deadline']
          // delete carga[i]['Allocated date time']
          // delete carga[i]['Begin Location Name']
          // delete carga[i]['Container No']
          // delete carga[i]['Customer Name']
          // delete carga[i]['End Addressline1']
          // delete carga[i]['End Addressline2']
          // delete carga[i]['End Addressline3']
          // delete carga[i]['End LATITUDE']
          // delete carga[i]['End LOCATIONCODE']
          // delete carga[i]['End LONGITUDE']
          // delete carga[i]['End Location Name']
          // delete carga[i]['End STREETNAME']
          // delete carga[i]['End Site']
          // delete carga[i]['End Site.1']
          // delete carga[i]['End Zip']
          // delete carga[i]['Task Type']
          // delete carga[i]['Vendor ID']
          duplicados++;
          //continue;
        } 

       let api = carga[i]?.id && carga[i].id != null  ? this.props.catalogo +"/updateJson" : this.props.catalogo;
       let method   = carga[i]?.id && carga[i].id != null  ? "put" : "post";
       carga[i].masivo = true;

      await  Crud_Catalogos(
          api,
          this.catalogo ,
          method,
          "",
          carga[i],
          "",
          "",
          "",
          []
        )
          .then((returnVal) => {

            console.log(returnVal)

            this.setState({current:i+1})
            
            cargados++; 
            nEn = i + 1;
            nDe = carga.length;
            
            if (returnVal) {
             
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Nuevo Movimiento Masivo Naviero Post id=" + returnVal.id;
              //console.log("928 this.usuariosLogObject");
              //console.log(this.usuariosLogObject);
              Crud_Catalogos(
                "usuariosLog",
                this.props.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
           

              // NotificationManager.success(
              //   "Carga Masiva en Catalogos: " + this.props.catalogo,
              //   "Carga con " + nDe + " Registros Finalizada! "
              // );


             
            }

            console.log("carga",i)
            console.log("TOTAL",carga.length)
            if (i === carga.length -1) {
            
            
            console.log(this.state.movsDuplicados)
            if(this.state.movsDuplicados.length > 0){
              data+= "Se han encontrador servicios duplicados: \n" + JSON.stringify(this.state.movsDuplicados) +"\n";
            }


           
            if(this.state.arrayFailItems.length > 0){
              data += ("Se encontraron errores en los siguientes servicios con clientes nulos : \n"+ JSON.stringify(this.state.arrayFailItems) + " \n"
              )
            }

    
            this.TextFile(data);
            
            NotificationManager.success(
              "Se han cargado: " + parseInt(nEn)  +" de " + carga.length + " movimientos" +
              " se a descargado un archivo con los servicios duplicados",
            "",
            12000
            );

            this.setState({total:0})
            this.setState({current:0})
            
            this.loader(false)

            }
              
               
              
            
          })
          .catch((err) => {
            console.log(err)
            if (err > 0) {
              //console.log("944 sin Errores=0 Err: ");
              //console.log(err);
              nEn = i + 1;
              nDe = carga.length;
              //console.log("Carga Masiva en Catalogo: " + this.props.catalogo, "Error en la Fila: " + nEn + " de " + nDe + " Favor de Verificar Sus Datos!");
              NotificationManager.error(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Error en la Fila: " +
                  nEn +
                  " de " +
                  nDe +
                  " Favor de Verificar Sus Datos!"
              );
            }
            if (i === carga.length - 1) {
              this.props.crud(
                "get",
                [],
                "",
                [],
                "navieraMovimientos/usuario/" + user.id
              );
              NotificationManager.success(
                "Carga Masiva en Catalogo: " + this.props.catalogo,
                "Finalizada! "
              );
              this.myModalN("none");
            }
            errResponse.push({ datos: carga[i] });
            if (err.response) {
              console.log(err);
            } else {
              console.log(
                "Error al Cargar el Catalogo:" +
                  this.state.catalogo +
                  " verifique el Nombre del Catalogo"
              );
            }
          });
      }
    }

    

    if(errores != 0){
      NotificationManager.warning(
        "Corriga los errores antes de continuar",
      ""
      );
      Errores = []
      
      return;
    }

    // if(duplicados > 0 && duplicados == parseInt(carga.length - 1)){
    //   let data = "Se han encontrador servicios duplicados: " + JSON.stringify(this.state.movsDuplicados);
    //   this.TextFile(data);
    //           NotificationManager.success(
    //             "Se han cargado: " + (parseInt(carga.length) - duplicados)  +" de " + carga.length + 
    //             " se a descargado un archivo con los servicios duplicados",
    //           "",
    //           9000
    //           );

    //           this.loader(false)
    // }
   
    
  };


  UploadProgressBar = ({ progress }) => (
    <div className="py-5">
      <div className="w-full bg-gray-200 rounded-full">
        <div
          className={`${
            progress === 0 ?
              'invisible'
              : ''
          } bg-indigo-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`}
          style={{ width: progress + "%" }}>
          {progress}%
        </div>
      </div>
    </div>
  );

  myModalN = (Muestra) => {
    // var x = document.getElementById("xlsModalNJson");
    // x.style.display = Muestra;
    if(Muestra=="block"){
      this.openModal()
    }
    if(Muestra == 'none'){
     this.closeModal()
    }
  }


  loader = (estado)=>{
    this.setState({isloading:estado});
  }

  openModal = () => {
    //this.props.Fclose();
    this.setState({modalIsOpen: true});
  }
  closeModal = () => {
    this.setState({modalIsOpen: false});
  }
  handleModalCloseRequest = () => {
    this.setState({modalIsOpen: false});
  }

  TextFile = (info) => {
    const element = document.createElement("a");
    const file = new Blob([info], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "errores.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  handleOnChange = (position) => {
    const updatedCheckedState = this.state.arrayCheckedState.map((item, index) =>
      index === position ? !item : item
    );

    this.setState({arrayCheckedState:updatedCheckedState});

    

  };


  checkDates(){
    let tempArrayInputs = []; 

    this.state.arrayDates.forEach(element => {
      let datearray = element.split("-");
                      tempArrayInputs.push(datearray[2] + '-' + datearray[1] + '-' + datearray[0]);
    });
        
  
    tempArrayInputs.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b) - new Date(a);
    });


    return  <div className="checkDates">
      <div className="col-12 check-list-item">
        {tempArrayInputs.map((date, index) => {
          return (
            
              <div className="col-2 check-list-item">
                <div className="left-section">
                <label class="label">
                  <input
                    type="checkbox"
                    id={"check"+index}
                    name={"check"+index}
                    value={date}
                    className="label__checkbox"
                    checked={this.state.arrayCheckedState[index]}
                    onChange={() => this.handleOnChange(index)}
                  />
                   <span class="label__text">
      <span class="label__check">
        <i class="fa fa-check icon"></i>
      </span>
    </span>
    </label>
                  <label htmlFor={`custom-checkbox-${index}`}>{date}</label>
                </div>
               
              </div>
            
          );
        })}
        {/* <li>
          <div className="toppings-list-item">
            <div className="left-section">Total:</div>
            <div className="right-section">{getFormattedPrice(total)}</div>
          </div>
        </li> */}
      </div>
    </div>
          
  }

  onImportJSON = (file) => {
  
   
    const { files } = file.target;

    console.log(files)
    
    //console.log("fileName");
    //console.log(files[0].name);
    Cuadros = [];
    TabTemp = [];
    TabPanelTemp = [];

    let data  = null
    const dataOld =  null;
    const filess = files[0];
    // console.log(filet)
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
    
      try {

        console.log("acacaca")
        const { result } = event.target;
     
        // const data  = (JSON.parse(result))
        // const dataOld  = (JSON.parse(result))
        const workbook = XLSX.read(result, { type: "binary" });


        for (const sheet in workbook.Sheets) {
        
        data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        console.log(data)

        }

        this.setState({read:false});
       
        const sheet = "navieraMovimientos";

          switch (sheet) {
            case "navieraMovimientos":
              if (data.length > 0) {

                this.setState({movsClientesPendientes:[]})
                this.setState({movsTransportPendientes:[]})
                this.setState({movsDuplicados:[]})
                this.setState({data:data});
                this.setState({dataOld:dataOld});
                this.setState({total:data.length})

                file.target.value = '';

                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                
              }
              {
                this.seleccionaFechas();
                
              }
              break;
            case "datos":
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({
                  data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]),
                });
                TabTemp.push(<Tab>navieraMovimientos</Tab>);
                TabPanelTemp.push(
                  <TabPanel>
                    <TablaCatalogo
                      catalogo={"navieraMovimientos"}
                      contenido={this.state.data}
                    />
                  </TabPanel>
                );
              }
              {
                this.validaArchivo();
              }
              break;

            default:
              console.log("Hoja No Válida!");
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        
        Cuadros.push(
          <Tabs forceRenderTabPanel={true} className={"div1"}>
            <TabList>{TabTemp}</TabList>
            {TabPanelTemp}
          </Tabs>
        );

        //this.setState({ validado: true });
        
        console.log("Carga de Archivo Exitosa！");
        id = 0;
        terrores = 0;
        sinError = 0;
      } catch (e) {
        console.log(e)
        NotificationManager.error(
          "Hay un error en el formato del JSON : " + e,
          " ",
          10000
        );
        
        this.setState({read:false});
        return;
        //console.log("Error! " + e);
      }
    };

    fileReader.onprogress = function(data) {
      console.log(data)
      if (data.lengthComputable) {
          var progress = parseInt( ((data.loaded / data.total) * 100), 10 );
          console.log(progress);
      }
    }

    fileReader.onprogress = function(event) {
      if (event.lengthComputable) {
       
              console.log("cargando")
         
      }
  };
      fileReader.onloadend = function(event) {
          console.log("termino")
      };
    fileReader.readAsBinaryString(files[0]);
    //this.myModalN("block");
   
   // document.getElementById("cerrar").focus();
  };

  render() {

    
    return (

      
      <div className="JSONN" id="JSONN">

      
        <a href="#miModalN">
          <button className="btn btn-primary">Importar JSON</button>
        </a>
        <input
          type="file"
          id="file"
          className="file"
          accept=".xlsx, .xls, .csv"
          onChange={(e)=> {this.setState({read:true}); this.onImportJSON(e)}}
        />
        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div id="xlsModalNJson" className="xlsModalNJson">
          <div className="modal-contenidoNJson">
            <center>
              <h2>{this.state.isloading ? "Validando.." : "Confirme su Carga"}</h2>
            </center>
            <div className="container-350">
              <div className="scroll-col col-sm-12">
                {this.state.isloading ?
                 <div className="progressBarJson" >
                 <LoadingProgress
                   useSpinner
                   shadow={true}
                   height={50}
                   active={this.state.isloading}
                   total={this.state.data.length}
                   current={this.state.current}
                   showCompact
                 />
               </div>
               :
                this.state.validado === false   ? (
                 <div>aaaaaaaaaaaa</div>
                ) : terrores == 0 ? (
                  <div>{Cuadros}</div>
                ) : (
                  
                  <div className="erroresJson">{Errores}</div>
                  
                )}
              </div>
            </div>

            <br></br>
          
              {!this.state.isloading ?
                <center>
              <button
                id="cerrar"
                className="azul"
                onClick={() => {this.myModalN('none'); Errores = []; this.contador = 0;}}
              >
                Cerrar y Corregir Archivo de JSON
              </button>
              <spam> </spam>
              <button
                id="cargar"
                className="azul"
                onClick={() =>  this.senData(this.state.data, terrores)}
              >
                Cargar Datos Correctos
              </button>
            </center>
            :null}
          </div>
        </div>
        </Modal>

        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.read}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
        <div className="xlsModalNJson">  
               <div className="loader test">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
              </div>

          </div>
              
              

          

        </Modal>


        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.envio}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div id="xlsModalNJson" className="xlsModalNJson">
          <div className="modal-contenidoNJson">
            <center>
              <h4>Enviando información</h4>
            </center>
            <div className="container-350">
              <div className="scroll-col col-sm-12">
              <div className="progressBarJson" >
                 <LoadingProgress
                   useSpinner
                   shadow={true}
                   height={50}
                   active={this.state.envio}
                   total={this.state.total}
                   current={this.state.current}
                   showCompact
                 />
               </div>
                
              </div>
            </div>

            <br></br>
            <center>
              <button
                id="cerrar"
                className="azul"
                onClick={() => {this.setState({envio:false})}}
              >
                Cerrar 
              </button>
              
             
            </center>
          </div>
        </div>
        </Modal>


        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalDatesOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style = {this.customStyles}
        >
          <div id="xlsModalNJson" className="xlsModalNJson">
          <div className="modal-contenidoNJson">
            <center>
              <h4>Selecciona las fechas de los servicios  a Cargar</h4>
            </center>
            <div className="container-350">
              <div className="scroll-col col-sm-12">
                { this.state.arrayCheckedState.length > 0 ?

                
                  this.checkDates():
                  null
                }
                
              </div>
            </div>

            <br></br>
            <center>
              <button
                id="cerrar"
                className="azul"
                onClick={() => {this.setState({modalDatesOpen:false}); this.setState({arrayDates:[]}); Errores = []; this.contador = 0;}}
              >
                Cerrar 
              </button>
              
              <button
                id="cargar"
                className="azul"
                
                disabled={this.state.arrayCheckedState.findIndex((item) => item == true) < 0 }
                onClick={() =>  this.updateData()}
              >
                Cargar Datos 
              </button>
            </center>
          </div>
        </div>
        </Modal>

        
      </div>
      
    );
  }
}
export default ImportarNJson;
