import React, { useState, useEffect } from "react";
import { Alert, Form } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import classes from "./ReaccionForm.module.css";
import { NotificationManager } from "react-notifications";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import useFetch from "../../../../customHooks/use-fetch";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
var user = JSON.parse(sessionStorage.getItem("usuario"));
const Reaccion = (props) => {
  const { error, sendRequest } = useFetch();
  const [Reaccion, setReaccion] = useState({});
  const [copied, setCopied] = useState("Copiar texto");
  const [incidencia, setIncidencia] = useState({ value: 0, label: "Seleciona una opción", data: null});
  const [incidencias, setIncidencias] = useState([{ value: 0, label: "Seleciona una opción", data: null}]);
  const [direccion, setDireccion] = useState({status: 200, message: "", data: {}});
  const [gpsReciente, setgpsReciente] = useState({});
  const [ubicacionIncidencia, setUbicacionIncidencia]= useState(true);
  const [mostrarIncidencia, setMostrarIncidencia]= useState(true);
  const [entrega, setEntrega]= useState({});
  const [reaccionSistem, setReaccionSistem ]= useState(
    {
      reaccion: {
          latitud: 0.0,// * (float8) Latitud de reacción
          longitud: 0.0,// * (float8) Latitud de reacción
          //fecha:  "",// * (timestamp) Fecha creción de reacción
          lugar_reaccion: "", // * (varchar128) String con dirección
          causa: "",// * (varchar32) Por lo general es la causa
      },
      movimiento: {
          marca: "",//(varchar16)
          placa: "",// * (varchar16) Obligatorio dependiendo del tipo de movimiento
          modelo: 0, //(int2)
          color: "", //(varchar16)
          economico: "", //(varchar16)
          caja: "",// * (varchar16) Oblogatorio si es naviero
          contenedor1: "", // * (float8) Obligatorio si es terreste
          contenedor2: "",// * (float8)
          operador: "",// * (varchar64)
          medio: "",//(varchar32) Medio de comunicación (número de teléfono)
          origen: "",//(varchar32)
          destino: "",//(varchar32)
          //capturista: "",// * (varchar64)
          transportista: "", // * (varchar32)
          plataforma: "",// * (varchar32)
          movimiento_id: 0,//(int4)
          estatus_carga: "vacio"//(varchar32) Se lleva carga, está vacío, etc.
      },
      evento: {
        usuario: (user.persona.nombre +" "+ user.persona.aPaterno + " " + user.persona.aMaterno ),//(varchar64) Es el usuario que está dando de alta la reacción
        sistema_id: 2,//* (integer) [1: SkyReaccion, 2: SkyOne, 3: DHL, 4: Alsea]
      }
    }
  );

  const getIncidencias =async()=> {    
    for( let e of props.entregas){
      if(e.estatus)
        setEntrega(e);
    }
    await getfuntion ( "get", "", "", "terrestreEntregasIncidencias/movimiento/" + props.movimiento, "listarIncidencias").then((resp) => {
      let incidenciasTemp= [...incidencias];
      let incidenciaSeleccionada= false;
      for( let i  of resp ){
        let incidenciaTemp= {...i};
        incidenciaTemp.idIncidencia= getIncidencia(i.idIncidencia);
        if( incidenciaTemp.idIncidencia.nombre ){
          incidenciasTemp.push(
            { value: incidenciaTemp.idEntregaIncidencia,
              label: incidenciaTemp.idIncidencia.nombre,
              data: incidenciaTemp
            });
          if(
            ( [13].includes(incidenciaTemp.idIncidencia.incidenciaTipo.id)
              || [3763, 4332, 4350, ,4351 ,4324, 4349].includes(incidenciaTemp.idIncidencia.id)
            )
            && !incidenciaSeleccionada){//13 robo
            incidenciaSeleccionada= true;
            setIncidencia(incidenciasTemp[incidenciasTemp.length-1]);
            getfuntion (
              "get",
              "",
              "",
              "gpsestatus/coordenadas_a_direccion/idggapp/"+props.getMovimiento.plataforma.idGg+"?latitud="+incidenciaTemp.latitud+"&longitud="+incidenciaTemp.longitud,
              "direccion");
          }
        }
      }
      setIncidencias(incidenciasTemp);
    });
    let gpsRecienteTemp= {};
    if( props.getMovimiento.movimientoGps && props.getMovimiento.movimientoGps.length ){
      for( let mg of props.getMovimiento.movimientoGps){
        await getfuntion ( "get", "", "", "gpsestatus/plataforma/"+props.getMovimiento.plataforma.idGg+"/gps/"+mg.gps.idGg, "gpsestatus").then((resp) => {
          if( !gpsRecienteTemp.ultimo_reporte ||  gpsRecienteTemp.ultimo_reporte_ms > resp.ultimo_reporte_ms ){
            gpsRecienteTemp= resp;
          }
        });
      }
      setgpsReciente(gpsRecienteTemp);
    }
    await sendRequest(
      {
        url: "reaccion/" + props.movimiento,
      },
      transformReaccion
    );
  }

  /**
   * Función que formatea los datos de una Reacción nueva.
   */
  const transformReaccionNueva = (reaccionNuevaObj) => {
    const movTem= props.getMovimiento;
    const unidad= props.unidad.length ? props.unidad[0].unidad: null;
    let economicoTemp= "";
    for( let mg of props.getMovimiento.movimientoGps){
      economicoTemp= economicoTemp+(economicoTemp.length?", ": "")+ mg.gps.nombre
    }
    setReaccion({
      ...Reaccion,//...reaccionNuevaObj,
      contenedorTotal: 0,//Terretres no tiene contenedores
      contenedor: movTem.caja,
      latitud: props.getMovimiento?.movimientoGps[0]?.gps
      ?.id
      ? props.gpsEstatusTerrestres[
          props.getMovimiento?.movimientoGps[0]?.gps?.id
        ]?.latitud
      : '',
      longitud: props.getMovimiento?.movimientoGps[0]?.gps
        ?.id
        ? props.gpsEstatusTerrestres[
            props.getMovimiento?.movimientoGps[0]?.gps?.id
          ]?.longitud
        : '',
      supervisor: (user.persona.nombre +" "+ user.persona.aPaterno + " " + user.persona.aMaterno ),//Usuario logueado
      cajaTipo: "",//Terretre no guarda esta infomrmacion
      clienteNombre: entrega.idEntrega? entrega.destino: "",//Seria la entrega
      remolquePlaca: "",//Terretre no guarda esta infomrmacion
      tractoMarca: unidad? unidad.unidadMarca.nombre: "",
      tractoModelo: unidad? parseInt(unidad.modelo): null,
      tractoColor: unidad? unidad.color: "",
      operadorNombre: movTem.operador.id> 1?(movTem.operador.nombre+" "+movTem.operador.paterno+" "+movTem.operador.materno): movTem.nombreOperador,
      operadorTelefono: movTem.operador.id> 1 ? movTem.operador.medioComunicacion: "",
      citaProgramada: entrega.idEntrega? entrega.citaCarga: "",//Seria la entrega
      economico: economicoTemp,//Seria el nombre del gps
      tractoPlaca: unidad? unidad.placa: "",//Seria la placa de la unidad
      origen: movTem.origen,
      destino: entrega.idEntrega? entrega.direccion.substring(0, 31): "",//Seria la entrega
      transportista: movTem.transportista.nombre,
      coordinadorNombre: movTem.transportista.contactoNombre,
      contenedorCargado: movTem.deRegreso? 0: 1,
      coordinadorTelefono:
        movTem.transportista.telefono1 && movTem.transportista.telefono1.length > 2
          ? movTem.transportista.telefono1
          : (
              movTem.transportista.telefono2 && movTem.transportista.telefono2.length > 2
              ? movTem.transportista.telefono2
              : ""
            ),
    });
  };

  /**
   * Función que formatea los datos de una Reacción ya existente.
   */
  const transformReaccion = (reaccionObj) => {
    setReaccion({
      ...reaccionObj,
      citaProgramada: new Date(reaccionObj.citaProgramada),
    });
  };

  useEffect(()=>{
    getIncidencias();
  },[]);

  /**
   * Consulta para traer los datos de la tabla Reacción.
   */
  /*
  useEffect(() => {
    sendRequest(
      {
        url: "reaccion/" + props.movimiento,
      },
      transformReaccion
    );
  }, [sendRequest]);*/

  /**
   * Segunda consulta para traer los datos si no existen en tabla Reacción.
   */
  useEffect(() => {
    if (Reaccion.id === null) {
      /*
      sendRequest(
        { url: "reaccion/feedTerrestre/" + props.movimiento },
        transformReaccionNueva
      );*/
      transformReaccionNueva();
    }else{
      setMostrarIncidencia(false);
    }
  }, [sendRequest, Reaccion.id]);

  /**
   * Función que crea la cadena de texto con los datos de reaccion
   */
  const createCadena = () => {
    let cadena = "==== DATOS DE REACCIÓN ====\n";
    for (const property in Reaccion) {
      if (Reaccion[property]) {
        cadena += `${property}: ${Reaccion[property]}\n`;
      }
    }
    return cadena;
  };

  /**
   * Función que copia el contenido del formulario en el portapapeles
   */
  const copyText = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(createCadena());
    setCopied("Copiado");
  };

  /**
   * Función que realiza un POST o PUT según sea el caso.
   */
  const formSubmitHandler = (event) => {
    event.preventDefault();
    
    sendRequest(
      {
        url: "reaccion",
        method: Reaccion.id ? "PUT" : "POST",
        body: {
          ...Reaccion,
          idPlataforma: props.plataforma,
          idMovimiento: props.movimiento,
          citaProgramada: timeToDateFill(Reaccion.citaProgramada),
        },
      },
      (datos) => {//nanci sierra https://one.skynavieros.com:8080/skyone_qa/asyc_arroba_nav/consolidado/688/73541
        let reaccionSistemTemp= {...reaccionSistem};
        reaccionSistemTemp.reaccion=
          { ...reaccionSistemTemp.reaccion,
            latitud: Reaccion.latitud,// * (float8) Latitud de reacción
            longitud: Reaccion.longitud,// * (float8) Latitud de reacción
            causa: incidencia.data.idIncidencia.nombre,// * (varchar32) Por lo general es la causa
          };
        const movTem= props.getMovimiento;
        reaccionSistemTemp.movimiento=
          { ...reaccionSistemTemp.movimiento,
            marca: Reaccion.tractoMarca,//(varchar16)
            placa: Reaccion.tractoPlaca,// * (varchar16) Obligatorio dependiendo del tipo de movimiento
            modelo: parseInt(Reaccion.tractoModelo), //(int2)
            color: Reaccion.tractoColor, //(varchar16)
            economico: Reaccion.economico, //(varchar16)
            caja: Reaccion.contenedor,// * (varchar16) Oblogatorio si es naviero
            operador: Reaccion.operadorNombre,// * (varchar64)
            medio: Reaccion.operadorTelefono,//(varchar32) Medio de comunicación (número de teléfono)
            origen: Reaccion.origen,//(varchar32)
            destino: Reaccion.destino,//(varchar32)
            transportista: movTem.transportista.nombre, // * (varchar32)
            plataforma: movTem.plataforma.nombre,// * (varchar32)
            movimiento_id: movTem.id,//(int4)
            estatus_carga: (Reaccion.contenedorCargado? "Cargado": "vacio"),//(varchar32) Se lleva carga, está vacío, etc.
          };
        
        reaccionSistemTemp.evento=
          { ...reaccionSistemTemp.evento,
            usuario: Reaccion.supervisor,//(varchar64) Es el usuario que está dando de alta la reacción
          };
        if( !Reaccion.id ){
          getfuntion ( "post", reaccionSistemTemp, "", "reaccion/sky_reaccion/"+datos.id, "sky_reaccion");
        }
        setReaccion((prev) => {
          return { ...prev, id: datos.id };
        });
        if (!error) {
          NotificationManager.success(
            Reaccion.id
              ? "Reacción actualizada correctamente"
              : "Reacción guardada correctamente",
            "Reacción"
          );
        }
      }
    );
    props.cerrarModal();
  };

  /**
   * Función que descarga el archivo de texto con la informacion de la reaccion.
   */
  const downloadTextFile = (event) => {
    event.preventDefault();
    const link = document.createElement("a");
    const file = new Blob([createCadena()], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = `Reaccion_${props.movimiento}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const getfuntion = ( metodo, obj, id, catalogo, stateVar) => {
    return Crud_Catalogos( catalogo, "movimientosReaccion", metodo, id, obj, "", stateVar).then((returnVal) => {
      switch (metodo) {
        case "get":
          if( stateVar === "gpsestatus" ){
            return returnVal;
          }
          if( stateVar === "direccion" ){
            let respuesta= {...returnVal};
            if(respuesta.data){
              respuesta.data=  JSON.parse(respuesta.data);
            }
            setDireccion( respuesta );
          }
          if( stateVar === "listarIncidencias" ){
            return returnVal;
          }
          break;
        case "post":
          if( stateVar === "sky_reaccion"){
            
          }
        default:
          break;
      }
    })
    .catch((err) => {
        console.log(err);
    });
  };

  const getIncidencia = (id) => {
    for (let item of props.incidenciasTotales) {
      if (id === item.id) {
        return item;
      }
    }
    return {};
  };

  return (
    <form className="needs-validation" onSubmit={formSubmitHandler}>
      {Reaccion.id && (
        <div style={{ backgroundColor: "#F7CD7A", borderRadius: 10 }}>
          <Alert severity="warning">
            Este movimiento ya tiene una reacción — ¡Sólo se editará la ya
            registrada!
          </Alert>
        </div>
      )}
      <hr className="my-4" />
      <h5 className="text-primary btn" onClick={()=> setMostrarIncidencia(!mostrarIncidencia)} hidden={ mostrarIncidencia }>
        <label htmlFor="incidencia" className="form-label">
          Recuperar informacion desde la incidencias
        </label>
      </h5>
      <div className={`row g-3 ${classes.section}`} hidden={!mostrarIncidencia}>
        <div className="col-sm-12">
          <label htmlFor="incidencia" className="form-label">
            Recuperar informacion desde la incidencias
          </label>
          <Select
            value={ incidencia }
            onChange={ (e)=>{
              setReaccion({
                ...Reaccion,
                latitud: e.data.latitud,
                longitud: e.data.longitud,
              });
              setIncidencia(e);
                getfuntion (
                  "get",
                  "",
                  "",
                  "gpsestatus/coordenadas_a_direccion/idggapp/"+props.getMovimiento.plataforma.idGg+"?latitud="+e.data.latitud+"&longitud="+e.data.longitud,
                  "direccion");
            }}
            options={ incidencias}/>
          { incidencia.value?
            <div>
              <p>Incidencia: { incidencia.data.idIncidencia.nombre }</p>
              <p>Comentario: { incidencia.data.comentario }</p>
              <h5 className="text-primary btn"
                onClick={()=>{
                  let reaccionRedTem= {...Reaccion};
                  reaccionRedTem.latitud= ubicacionIncidencia? gpsReciente.latitud: incidencia.data.latitud;
                  reaccionRedTem.longitud= ubicacionIncidencia? gpsReciente.longitud: incidencia.data.longitud;
                  setUbicacionIncidencia(!ubicacionIncidencia);
                  setReaccion(reaccionRedTem);
                  let reaccionSistemTemp= {...reaccionSistem};
                    reaccionSistemTemp.reaccion={ ...reaccionSistemTemp.reaccion,
                        latitud: ubicacionIncidencia? gpsReciente.latitud: incidencia.data.latitud,
                        longitud: ubicacionIncidencia? gpsReciente.longitud: incidencia.data.longitud,
                        lugar_reaccion: (
                          ubicacionIncidencia && direccion.data && direccion.data.location && direccion.data.location.address
                            ? direccion.data.location.address
                          : (gpsReciente.direccion? gpsReciente.direccion: ""))
                      };
                    setReaccionSistem(reaccionSistemTemp);
                }}
              >Usar ubicación { ubicacionIncidencia?"actual del gps": "de la incidencia"}</h5>
              <p>Latitud: { ubicacionIncidencia? incidencia.data.latitud: gpsReciente.latitud }
              , Longitud: { ubicacionIncidencia? incidencia.data.longitud: gpsReciente.longitud }</p>
              <p>
                <label htmlFor="dirreccion" className="form-label">
                  Dirección
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={reaccionSistem.lugar_reaccion}
                  onChange={(e) => {
                    let reaccionSistemTemp= {...reaccionSistem};
                    reaccionSistemTemp.reaccion={ ...reaccionSistemTemp.reaccion,
                        lugar_reaccion: e.target.value };
                    setReaccionSistem(reaccionSistemTemp);
                  }}
                  maxlength="30"
                />
              </p>
            </div>
          :null}
        </div>
      </div>
      <hr className="my-4" />
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="Supervisor" className="form-label">
            Supervisor responsable
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.supervisor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, supervisor: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Reporte" className="form-label">
            Reporte
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.reporte}
            onChange={(event) => {
              setReaccion({ ...Reaccion, reporte: event.target.value });
            }}
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="reporteURL" className="form-label">
            Reporte URL
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.reporteUrl}
            onChange={(event) => {
              setReaccion({ ...Reaccion, reporteUrl: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información GPS</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="Latitud" className="form-label">
            Latitud
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.latitud}
            onChange={(event) => {
              setReaccion({ ...Reaccion, latitud: event.target.value });
            }}
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Longitud" className="form-label">
            Longitud
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.longitud}
            onChange={(event) => {
              setReaccion({ ...Reaccion, longitud: event.target.value });
            }}
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del Movimiento</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-3">
          <label htmlFor="Linea" className="form-label">
            Línea de transporte
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.transportista}
            onChange={(event) => {
              setReaccion({ ...Reaccion, transportista: event.target.value });
            }}
            maxlength="255"
          />
        </div>

        <div className="col-sm-3">
          <label htmlFor="numContenedor" className="form-label">
            Número de contenedor/Caja
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.contenedor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedor: event.target.value });
            }}
            maxlength="100"
          />
        </div>

        <div className="col-sm-3">
          <label htmlFor="Tipocaja" className="form-label">
            Tipo de caja
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.cajaTipo}
            onChange={(event) => {
              setReaccion({ ...Reaccion, cajaTipo: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-3">
          <label htmlFor="NombreCliente" className="form-label">
            Nombre del cliente
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.clienteNombre}
            onChange={(event) => {
              setReaccion({ ...Reaccion, clienteNombre: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del contenedor</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="NUmeroContenedor" className="form-label">
            Número de contenedores
          </label>
          <input
            type="number"
            className="form-control"
            value={Reaccion.contenedorTotal}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedorTotal: event.target.value });
            }}
            min="0"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="ColorContenedor" className="form-label">
            Color del contenedor
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.contenedorColor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedorColor: event.target.value });
            }}
            maxlength="20"
          />
        </div>

        <div className="col-sm-4">
          <div className="custom-control custom-switch">
            <Form.Switch
              type="Checkbox"
              onChange={(event) => {
                setReaccion({
                  ...Reaccion,
                  contenedorCargado: event.target.checked ? 1 : 0,
                });
              }}
              checked={Reaccion.contenedorCargado}
            />
            <label htmlFor="Cargado" className="form-label">
            {Reaccion.contenedorCargado? "Cargado": "Vacio"}
            </label>
          </div>
        </div>
      </div>
      <hr className="my-4" />

      <h4 className="mb-3">Información tracto</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="PlacaRemolque" className="form-label">
            Placa del Remolque
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.remolquePlaca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, remolquePlaca: event.target.value });
            }}
            maxlength="20"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Economico" className="form-label">
            Económico tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.economico}
            onChange={(event) => {
              setReaccion({ ...Reaccion, economico: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Economico" className="form-label">
            Económico remolque
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.economicor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, economicor: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="PlacaTracto" className="form-label">
            Placa del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoPlaca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoPlaca: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="MarcaTracto" className="form-label">
            Marca del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoMarca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoMarca: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ModeloTracto" className="form-label">
            Modelo del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoModelo}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoModelo: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ColorTracto" className="form-label">
            Color del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoColor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoColor: event.target.value });
            }}
            maxlength="200"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Operador" className="form-label">
            Nombre del Operador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.operadorNombre}
            onChange={(event) => {
              setReaccion({ ...Reaccion, operadorNombre: event.target.value });
            }}
            maxlength="50"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="TelefonoOperador" className="form-label">
            Teléfono del operador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.operadorTelefono}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                operadorTelefono: event.target.value,
              });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ClaveAmago" className="form-label">
            Clave de no amago
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.claveNoAmago}
            onChange={(event) => {
              setReaccion({ ...Reaccion, claveNoAmago: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Origen" className="form-label">
            Dirección de origen
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.origen}
            onChange={(event) => {
              setReaccion({ ...Reaccion, origen: event.target.value });
            }}
            maxlength="500"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Destino" className="form-label">
            Dirección de destino
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.destino}
            onChange={(event) => {
              setReaccion({ ...Reaccion, destino: event.target.value });
            }}
            maxlength="500"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="HoraCita" className="form-label">
            Hora cita cliente
          </label>
          <DateTimePicker
            onChange={(date) => {
              setReaccion({ ...Reaccion, citaProgramada: date });
            }}
            value={
              Reaccion.citaProgramada ? new Date(Reaccion.citaProgramada) : null
            }
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del coordinador</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="NombreCoo" className="form-label">
            Nombre del coordinador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.coordinadorNombre}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                coordinadorNombre: event.target.value,
              });
            }}
            maxlength="255"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="TelefonoCoo" className="form-label">
            Teléfono del coordinador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.coordinadorTelefono}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                coordinadorTelefono: event.target.value,
              });
            }}
            maxlength="100"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="NumSerie" className="form-label">
            Número de serie
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.serieNumero}
            onChange={(event) => {
              setReaccion({ ...Reaccion, serieNumero: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="NumMotor" className="form-label">
            Número de motor
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.motorNumero}
            onChange={(event) => {
              setReaccion({ ...Reaccion, motorNumero: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Observaciones" className="form-label">
            Observaciones
          </label>
          <textarea
            className="form-control"
            value={Reaccion.observaciones}
            onChange={(event) => {
              setReaccion({ ...Reaccion, observaciones: event.target.value });
            }}
            maxlength="255"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="kilometro" className="form-label">
            Kilómetro
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.kilometro}
            onChange={(event) => {
              setReaccion({ ...Reaccion, kilometro: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <div className={classes.tooltip}>
          <button
            className={`btn btn-dark btn-lg px-4 ${classes.button}`}
            onClick={copyText}
          >
            Copiar contenido
          </button>
          <span className={classes.tooltiptext}>{copied}</span>
        </div>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
          style={{ marginLeft: "10px" }}
        >
          <button
            className="btn btn-secondary btn-lg px-4 me-sm-3 fw-bold"
            onClick={downloadTextFile}
          >
            Descargar archivo
          </button>
          <button
            className="btn btn-info btn-lg px-4 me-sm-3 fw-bold"
            type="submit"
          >
            {Reaccion.id ? "Editar" : "Guardar"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Reaccion;
