import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

class PatiosVaciosForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            patio: "",
            direccion: "",
            latitud: 0,
            longitud: 0,

            accion: this.props.accion, // nuevo, modificar
            crudbutonEnable: true, // Se desabilita cuando se hace click
        };
        this.PatiosVaciosObj = {
            id: this.props.elementos.id,
            patio: this.props.elementos.patio,
            direccion: this.props.elementos.direccion,
            latitud: this.props.elementos.latitud,
            longitud: this.props.elementos.longitud,
        };
        this.enableSave = this.enableSave.bind(this);
    }

    isEnable = (Accion) => {
        let borrar = "corredores_borrar";
        let actualizar = "corredores_editar";
        let agregar = "corredores_agregar";
        var privilegios = this.props.stor.privilegios_object;
        var perfil = JSON.parse(sessionStorage.getItem("perfil"));
        for (let i = 0; i < privilegios.length; i++) {
          if (Accion === "borrar") {
            if (privilegios[i].clave == borrar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
          if (Accion === "actualizar") {
            if (privilegios[i].clave == actualizar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
          if (Accion === "agregar") {
            if (privilegios[i].clave == agregar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
        }
        return false;
    };

    componentDidMount = () => {
        if (this.state.accion === "nuevo") {
            this.PatiosVaciosObj = {
                patio: "",
                direccion: "",
                latitud: null,
                longitud: null,
            };
        } else {
          this.setState({ patio: this.props.elementos.patio });
          this.setState({ direccion: this.props.elementos.direccion });
          this.setState({ latitud: this.props.elementos.latitud });
          this.setState({ longitud: this.props.elementos.longitud });
          this.PatiosVaciosObj =  this.props.elementos;
        }        
    };

    enableSave = () => {
        // console.log("Enable button: "+this.state.nombre)
        if (this.state.patio.length > 0) {
          return true;
        } else {
          return false;
        }
    };

    guardarDireccion = () => {
        if (this.state.accion === "modificar") this.crud_put();
        else this.crud_post();
        this.props.cerrarModal();
      };

    changePatio = (event) => {
        this.setState({ patio: event.target.value });
        this.PatiosVaciosObj.patio = event.target.value;
    };

    changeDireccion = (event) => {
        this.setState({ direccion: event.target.value });
        this.PatiosVaciosObj.direccion = event.target.value;
    };
    
    changeLatitud = (event) => {
        this.setState({ latitud: event.target.value });
        this.PatiosVaciosObj.latitud = event.target.value;
    };
    
    changeLongitud = (event) => {
        this.setState({ longitud: event.target.value });
        this.PatiosVaciosObj.longitud = event.target.value;
    };

    delete() {
        this.setState({ crudbutonEnable: false });
        this.props.getfuntion("delete", "", this.state.id);
    };
    
    crud_put() {
        this.setState({ crudbutonEnable: false });
        this.props.getfuntion("put", this.PatiosVaciosObj, "", "patiosVacios", "patiosVacios");
    };
    
    crud_post() {
        this.setState({ crudbutonEnable: false });
        this.props.getfuntion("post", this.PatiosVaciosObj, "", "patiosVacios", "patiosVacios");
    };

    render() {
        return (
            <div>
                {this.state.accion === "modificar" ? (
                    <h1>{this.props.elementos.patio}</h1>
                ) : null}
                <Grid.Row>
                    <Grid.Col md={12} xl={12}>
                        <Grid.Row>
                        <Grid.Col md={3} lg={3}>
                            <Form.Group label="id">
                            <Form.Input
                                name="id"
                                disabled
                                type="number"
                                value={this.state.id}
                            />
                            </Form.Group>
                        </Grid.Col>

                        <Grid.Col md={9} lg={9}>
                            <Form.Group label="Patio">
                            <Form.Input
                                name="patio"
                                type="text"
                                value={this.state.patio}
                                onChange={this.changePatio}
                                maxlength="30"
                            />
                            </Form.Group>
                        </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                        <Grid.Col md={6} lg={6}>
                            <Form.Group label="Latitud">
                            <Form.Input
                                name="latitud"
                                type="number"
                                value={this.state.latitud}
                                onChange={this.changeLatitud}
                                maxlength="20"
                            />
                            </Form.Group>
                        </Grid.Col>
                        <Grid.Col md={6} lg={6}>
                            <Form.Group label="Longitud">
                            <Form.Input
                                name="longitud"
                                type="number"
                                value={this.state.longitud}
                                onChange={this.changeLongitud}
                                maxlength="20"
                            />
                            </Form.Group>
                        </Grid.Col>
                        </Grid.Row>                    
                        <Grid.Row>
                        <Grid.Col md={12} lg={12}>
                            <Form.Group label="Dirección" style="color:red">
                            <Form.Input
                                name="direccion"
                                type="text"
                                value={this.state.direccion}
                                onChange={this.changeDireccion}
                            />
                            </Form.Group>
                        </Grid.Col>
                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>

                <div className="float-right">
                {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
                    <span class="badge">
                    <Button
                        target="_blank"
                        size="sm"
                        RootComponent="a"
                        color="primary"
                        disabled={
                        !this.enableSave() ||
                        !this.state.crudbutonEnable 
                        }
                        onClick={this.guardarDireccion}
                    >
                        <span class="badge">
                        <Icon link={true} name="save" />
                        Guardar
                        </span>
                    </Button>
                    </span>
                ) : null}                
                </div>
            </div>
        );
    }
}
export default PatiosVaciosForm;