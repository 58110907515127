import React, { useState, useEffect } from "react";
import { Alert, Form } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import classes from "./ReaccionForm.module.css";
import { NotificationManager } from "react-notifications";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import useFetch from "../../../../customHooks/use-fetch";

const ReaccionForm = (props) => {
  const { error, sendRequest } = useFetch();
  const [Reaccion, setReaccion] = useState({});
  const [copied, setCopied] = useState("Copiar texto");

  /**
   * Función que formatea los datos de una Reacción nueva.
   */
  const transformReaccionNueva = (reaccionNuevaObj) => {
    setReaccion({
      ...reaccionNuevaObj,
      latitud: props.getMovimiento?.movimientoGps[0]?.gps
      ?.id
      ? props.gpsEstatusNavieros[
          props.getMovimiento?.movimientoGps[0]?.gps?.id
        ]?.latitud
      : '',
      longitud: props.getMovimiento?.movimientoGps[0]?.gps
        ?.id
        ? props.gpsEstatusNavieros[
            props.getMovimiento?.movimientoGps[0]?.gps?.id
          ]?.longitud
        : '',
      contenedorTotal:
        reaccionNuevaObj.no_contenedores === null ||
        reaccionNuevaObj.no_contenedores === "1"
          ? "1"
          : "2",
      supervisor:
        document.querySelector(".avatar").nextElementSibling.children[0]
          .innerHTML,
      cajaTipo: reaccionNuevaObj.cajatipo,
      clienteNombre: reaccionNuevaObj.clientenombre,
      remolquePlaca: reaccionNuevaObj.remolqueplaca,
      tractoMarca: reaccionNuevaObj.tractomarca,
      tractoModelo: reaccionNuevaObj.tractomodelo,
      tractoColor: reaccionNuevaObj.tractocolor,
      operadorNombre: reaccionNuevaObj.operadornombre,
      operadorTelefono: reaccionNuevaObj.operadortelefono,
      citaProgramada: reaccionNuevaObj.citaprogramada
        ? new Date(reaccionNuevaObj.citaprogramada)
        : new Date(),
      economico: reaccionNuevaObj.economicot,
      tractoPlaca: reaccionNuevaObj.tractoplaca,
      origen:
        reaccionNuevaObj.id_movimiento_tipo === 1 ||
        reaccionNuevaObj.id_movimiento_tipo === 3
          ? reaccionNuevaObj.corredor
          : reaccionNuevaObj.domicilio,
      destino:
        reaccionNuevaObj.id_movimiento_tipo === 2 ||
        reaccionNuevaObj.id_movimiento_tipo === 4
          ? reaccionNuevaObj.corredor
          : reaccionNuevaObj.domicilio,
      coordinadorNombre: reaccionNuevaObj.contacto_nombre
        ? reaccionNuevaObj.contacto_nombre
        : "",
      coordinadorTelefono:
        reaccionNuevaObj.telefono1 && reaccionNuevaObj.telefono2
          ? `${reaccionNuevaObj.telefono1}, ${reaccionNuevaObj.telefono2}`
          : reaccionNuevaObj.telefono1
          ? reaccionNuevaObj.telefono1
          : reaccionNuevaObj.telefono2
          ? reaccionNuevaObj.telefono2
          : "",
    });
  };

  /**
   * Función que formatea los datos de una Reacción ya existente.
   */
  const transformReaccion = (reaccionObj) => {
    setReaccion({
      ...reaccionObj,
      citaProgramada: new Date(reaccionObj.citaProgramada),
    });
  };

  /**
   * Consulta para traer los datos de la tabla Reacción.
   */
  useEffect(() => {
    sendRequest(
      {
        url: "reaccion/" + props.movimiento,
      },
      transformReaccion
    );
  }, [sendRequest]);

  /**
   * Segunda consulta para traer los datos si no existen en tabla Reacción.
   */
  useEffect(() => {
    if (Reaccion.id === null) {
      sendRequest(
        { url: "reaccion/feed/" + props.movimiento },
        transformReaccionNueva
      );
    }
  }, [sendRequest, Reaccion.id]);

  /**
   * Función que crea la cadena de texto con los datos de reaccion
   */
  const createCadena = () => {
    let cadena = "==== DATOS DE REACCIÓN ====\n";
    for (const property in Reaccion) {
      if (Reaccion[property]) {
        cadena += `${property}: ${Reaccion[property]}\n`;
      }
    }
    return cadena;
  };

  /**
   * Función que copia el contenido del formulario en el portapapeles
   */
  const copyText = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(createCadena());
    setCopied("Copiado");
  };

  /**
   * Función que realiza un POST o PUT según sea el caso.
   */
  const formSubmitHandler = (event) => {
    event.preventDefault();
    sendRequest(
      {
        url: "reaccion",
        method: Reaccion.id !== undefined ? "PUT" : "POST",
        body: {
          ...Reaccion,
          idPlataforma: props.plataforma,
          idMovimiento: props.movimiento,
          citaProgramada: timeToDateFill(Reaccion.citaProgramada),
        },
      },
      (datos) => {
        setReaccion((prev) => {
          return { ...prev, id: datos.id };
        });
        if (!error) {
          NotificationManager.success(
            Reaccion.id
              ? "Reacción actualizada correctamente"
              : "Reacción guardada correctamente",
            "Reacción"
          );
        }
      }
    );
    props.cerrarModal();
  };

  /**
   * Función que descarga el archivo de texto con la informacion de la reaccion.
   */
  const downloadTextFile = (event) => {
    event.preventDefault();
    const link = document.createElement("a");
    const file = new Blob([createCadena()], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = `Reaccion_${props.movimiento}.txt`;
    link.click();
    URL.revokeObjectURL(link.href);
  };

  return (
    <form className="needs-validation" onSubmit={formSubmitHandler}>
      {Reaccion.id && (
        <div style={{ backgroundColor: "#F7CD7A", borderRadius: 10 }}>
          <Alert severity="warning">
            Este movimiento ya tiene una reacción — ¡Sólo se editará la ya
            registrada!
          </Alert>
        </div>
      )}
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="Supervisor" className="form-label">
            Supervisor responsable
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.supervisor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, supervisor: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Reporte" className="form-label">
            Reporte
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.reporte}
            onChange={(event) => {
              setReaccion({ ...Reaccion, reporte: event.target.value });
            }}
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="reporteURL" className="form-label">
            Reporte URL
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.reporteUrl}
            onChange={(event) => {
              setReaccion({ ...Reaccion, reporteUrl: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información GPS</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="Latitud" className="form-label">
            Latitud
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.latitud}
            onChange={(event) => {
              setReaccion({ ...Reaccion, latitud: event.target.value });
            }}
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Longitud" className="form-label">
            Longitud
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.longitud}
            onChange={(event) => {
              setReaccion({ ...Reaccion, longitud: event.target.value });
            }}
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del Movimiento</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-3">
          <label htmlFor="Linea" className="form-label">
            Línea de transporte
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.transportista}
            onChange={(event) => {
              setReaccion({ ...Reaccion, transportista: event.target.value });
            }}
            maxlength="255"
          />
        </div>

        <div className="col-sm-3">
          <label htmlFor="numContenedor" className="form-label">
            Número de contenedor
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.contenedor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedor: event.target.value });
            }}
            maxlength="100"
          />
        </div>

        <div className="col-sm-3">
          <label htmlFor="Tipocaja" className="form-label">
            Tipo de caja
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.cajaTipo}
            onChange={(event) => {
              setReaccion({ ...Reaccion, cajaTipo: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-3">
          <label htmlFor="NombreCliente" className="form-label">
            Nombre del cliente
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.clienteNombre}
            onChange={(event) => {
              setReaccion({ ...Reaccion, clienteNombre: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del contenedor</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="NUmeroContenedor" className="form-label">
            Número de contenedores
          </label>
          <input
            type="number"
            className="form-control"
            value={Reaccion.contenedorTotal}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedorTotal: event.target.value });
            }}
            min="0"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="ColorContenedor" className="form-label">
            Color del contenedor
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.contenedorColor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, contenedorColor: event.target.value });
            }}
            maxlength="20"
          />
        </div>

        <div className="col-sm-4">
          <div className="custom-control custom-switch">
            <Form.Switch
              type="Checkbox"
              onChange={(event) => {
                setReaccion({
                  ...Reaccion,
                  contenedorCargado: event.target.checked ? 1 : 0,
                });
              }}
              checked={Reaccion.contenedorCargado}
            />
            <label htmlFor="Cargado" className="form-label">
              Cargado o vacío
            </label>
          </div>
        </div>
      </div>
      <hr className="my-4" />

      <h4 className="mb-3">Información tracto</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="PlacaRemolque" className="form-label">
            Placa del Remolque
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.remolquePlaca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, remolquePlaca: event.target.value });
            }}
            maxlength="20"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Economico" className="form-label">
            Económico tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.economico}
            onChange={(event) => {
              setReaccion({ ...Reaccion, economico: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="Economico" className="form-label">
            Económico remolque
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.economicor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, economicor: event.target.value });
            }}
            maxlength="30"
          />
        </div>

        <div className="col-sm-4">
          <label htmlFor="PlacaTracto" className="form-label">
            Placa del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoPlaca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoPlaca: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="MarcaTracto" className="form-label">
            Marca del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoMarca}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoMarca: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ModeloTracto" className="form-label">
            Modelo del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoModelo}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoModelo: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ColorTracto" className="form-label">
            Color del Tracto
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.tractoColor}
            onChange={(event) => {
              setReaccion({ ...Reaccion, tractoColor: event.target.value });
            }}
            maxlength="200"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Operador" className="form-label">
            Nombre del Operador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.operadorNombre}
            onChange={(event) => {
              setReaccion({ ...Reaccion, operadorNombre: event.target.value });
            }}
            maxlength="50"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="TelefonoOperador" className="form-label">
            Teléfono del operador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.operadorTelefono}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                operadorTelefono: event.target.value,
              });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="ClaveAmago" className="form-label">
            Clave de no amago
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.claveNoAmago}
            onChange={(event) => {
              setReaccion({ ...Reaccion, claveNoAmago: event.target.value });
            }}
            maxlength="20"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Origen" className="form-label">
            Dirección de origen
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.origen}
            onChange={(event) => {
              setReaccion({ ...Reaccion, origen: event.target.value });
            }}
            maxlength="500"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Destino" className="form-label">
            Dirección de destino
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.destino}
            onChange={(event) => {
              setReaccion({ ...Reaccion, destino: event.target.value });
            }}
            maxlength="500"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="HoraCita" className="form-label">
            Hora cita cliente
          </label>
          <DateTimePicker
            onChange={(date) => {
              setReaccion({ ...Reaccion, citaProgramada: date });
            }}
            value={
              Reaccion.citaProgramada ? new Date(Reaccion.citaProgramada) : ""
            }
          />
        </div>
      </div>
      <hr className="my-4" />
      <h4 className="mb-3">Información del coordinador</h4>
      <div className={`row g-3 ${classes.section}`}>
        <div className="col-sm-4">
          <label htmlFor="NombreCoo" className="form-label">
            Nombre del coordinador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.coordinadorNombre}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                coordinadorNombre: event.target.value,
              });
            }}
            maxlength="255"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="TelefonoCoo" className="form-label">
            Teléfono del coordinador
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.coordinadorTelefono}
            onChange={(event) => {
              setReaccion({
                ...Reaccion,
                coordinadorTelefono: event.target.value,
              });
            }}
            maxlength="100"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="NumSerie" className="form-label">
            Número de serie
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.serieNumero}
            onChange={(event) => {
              setReaccion({ ...Reaccion, serieNumero: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="NumMotor" className="form-label">
            Número de motor
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.motorNumero}
            onChange={(event) => {
              setReaccion({ ...Reaccion, motorNumero: event.target.value });
            }}
            maxlength="30"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="Observaciones" className="form-label">
            Observaciones
          </label>
          <textarea
            className="form-control"
            value={Reaccion.observaciones}
            onChange={(event) => {
              setReaccion({ ...Reaccion, observaciones: event.target.value });
            }}
            maxlength="255"
          />
        </div>
        <div className="col-sm-4">
          <label htmlFor="kilometro" className="form-label">
            Kilómetro
          </label>
          <input
            type="text"
            className="form-control"
            value={Reaccion.kilometro}
            onChange={(event) => {
              setReaccion({ ...Reaccion, kilometro: event.target.value });
            }}
            maxlength="255"
          />
        </div>
      </div>
      <hr className="my-4" />
      <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <div className={classes.tooltip}>
          <button
            className={`btn btn-dark btn-lg px-4 ${classes.button}`}
            onClick={copyText}
          >
            Copiar contenido
          </button>
          <span className={classes.tooltiptext}>{copied}</span>
        </div>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
          style={{ marginLeft: "10px" }}
        >
          <button
            className="btn btn-secondary btn-lg px-4 me-sm-3 fw-bold"
            onClick={downloadTextFile}
          >
            Descargar archivo
          </button>
          <button
            className="btn btn-info btn-lg px-4 me-sm-3 fw-bold"
            type="submit"
          >
            {Reaccion.id ? "Editar" : "Guardar"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ReaccionForm;
