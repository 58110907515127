import  React, { Component } from "react"; 
import {Grid, StampCard} from "tabler-react";
import SiteWrapper from "../../../SiteWrapper.react";

const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var naviera=    false;
var terrestre=  false;
//test
class AdminstracionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminstracion: [],
      plataforma:[],
      gps:[],
      alertas:[],
      catalogos:[],
      unidades:[]
    };
    this.funcionalidades= [];
  }
  componentWillMount =() =>{
    for(let item of currentUser){
      switch (item.plataforma.plataformaTipo.nombre.toUpperCase()) {
        case "NAVIERA": naviera=true;   break;
        case "TERRESTRE": terrestre=true; break;
          
        default:  break;
      }
    }
    this.formarMenu();
  }
  componentDidUpdate =()=>{
    if(this.funcionalidades !== this.props.funcionalidades){ this.formarMenu(); }
  }
  formarMenu=()=>{
    this.funcionalidades= this.props.funcionalidades;
    let adminstracion= [];
    let plataforma=[];
    let gps=[];
    let alertas=[];
    let catalogos=[];
    let unidades=[];
    for(let item of this.funcionalidades){
      switch (item.nombre) {
        case "Perfiles":    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                  <StampCard color="primary" icon="eye" header={item.nombre}/>
                                                </Grid.Col>);
          break;

        case "Clientes":    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                  <StampCard color="primary" icon="user" header={item.nombre} footer={""} />
                                                </Grid.Col>);
          break;
        case "Usuarios":    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                  <StampCard color="primary" icon="users" header={item.nombre}/>
                                                </Grid.Col>);
          break;
        case "Personas":    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                  <StampCard color="primary" icon="users" header={item.nombre} footer={""} />
                                                </Grid.Col>  );
          break;
        case "Privilegios": if(currentUser[0].perfil.nombre== "Administrador"){
                            adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                  <StampCard color="primary" icon="list" header={item.nombre}/>
                                                </Grid.Col>);}
          break;/** Plataformas */
        case "Bitacora monitoreo":  if(currentUser[0].perfil.nombre== "Administrador"){
                                    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="edit" header={item.nombre}/>
                                                      </Grid.Col>);}
          break;
        case "Acumuladores":        if(currentUser[0].perfil.nombre== "Administrador"){
                                    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="battery" header={item.nombre}/>
                                                      </Grid.Col> );}
          break;
        case "Otd":                 if(naviera){
                                    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="database" header={item.nombre} footer={""} />
                                                      </Grid.Col>);}
          break;
        case "Navieras":            if(naviera){
                                    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="anchor" header={item.nombre}/>
                                                      </Grid.Col> );}
          break;
        case "Plataformas":         plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="package" header={item.nombre}/>
                                                      </Grid.Col> );
          break;
        case "Transportistas":      plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "Plataformas tipo":    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="airplay" header={item.nombre}/>
                                                      </Grid.Col> );
          break;
        case "Corredores":          plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="git-merge" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "Salas":               plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="airplay" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "Destinos":            if(terrestre){
                                    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                      </Grid.Col>);}
          break;/** GPS */
        case "GPS":                 if(naviera){
                                    gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="crosshair" header={item.nombre} footer={""} />
                                                      </Grid.Col>);}
          break;
        case "GPS Fijos":           if(terrestre){
                                    gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="crosshair" header={item.nombre} footer={""} />
                                                      </Grid.Col>);}
          break;
        case "GPS estatus":         gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="flag" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "GPS hardware":        gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="hard-drive" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "GPS tipo colocación": gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="compass" header={item.nombre}/>
                                                      </Grid.Col>);
          break;/**Alertas */
        case "Alertas Categorias":  alertas.push(     <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="users" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "Alertas Tipos":       alertas.push(     <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="users" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;/**Catalogos */
        case "Bloques":             if(currentUser[0].perfil.nombre== "Administrador"){
                                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="bookmark" header={item.nombre} footer={""} />
                                                      </Grid.Col>);}
          break;
        case "Funcionalidades":     if(currentUser[0].perfil.nombre== "Administrador"){
                                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="sliders" header={item.nombre}/>
                                                      </Grid.Col>);}
          break;
        case "Categorias":          if(currentUser[0].perfil.nombre== "Administrador"){
                                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="slack" header={item.nombre}/>
                                                      </Grid.Col>);}
          break;
        case "Etiquetas":           if(currentUser[0].perfil.nombre== "Administrador"){
                                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="tag" header={item.nombre}/>
                                                      </Grid.Col>);}
          break;
        case "Movimientos Tipo":    if(currentUser[0].perfil.nombre== "Administrador"){
                                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="chevrons-up" header={item.nombre}/>
                                                      </Grid.Col>);}
          break;
        case "Informes":            catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="user" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "Patios Vacios":       catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="user" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "Incidencias":         catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="zap" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "Incidencia tipos":    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="copy" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "Controladores de patios": catalogos.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                          <StampCard color="primary" icon="map-pin" header={item.nombre}/>
                                                        </Grid.Col>);
          break;
        case "Operadores":          catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="activity" header={item.nombre} footer={""} />
                                                      </Grid.Col>   );
          break;
        case "Rutas estatus":       catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="terminal" header={item.nombre}/>
                                                      </Grid.Col>);
          break;
        case "Operaciones Tipo":    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="activity" header={item.nombre} footer={""} />
                                                      </Grid.Col>);
          break;
        case "Operaciones Estatus": catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                        <StampCard color="primary" icon="bar-chart-2" header={item.nombre}/>
                                                      </Grid.Col>);
          break;/**Unidades */
        case "Unidades":                  if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        case "Unidades Marcas":           if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        case "Unidades Tipos":            if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        case "Unidad Elemento":           if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        case "Unidad Estatus Operacion":  if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        case "Unidad Tipo Colocacion":    if(terrestre){
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={item.nombre} footer={""} />
                                                          </Grid.Col>);}
          break;
        
        default:
          break;
      }
    }
    this.setState({adminstracion: adminstracion});
    this.setState({plataforma: plataforma});
    this.setState({gps: gps });
    this.setState({alertas: alertas });
    this.setState({catalogos: catalogos});
    this.setState({unidades: unidades});
  }
  render(){
    return (    
      <SiteWrapper>
        <div className="container mt-3">
          { this.state.adminstracion.length > 0 ? //**********************************************************Admistracion
            <div>
              <hr style={ estilo } />
              <h5>Administración</h5>
              <Grid.Row>  {this.state.adminstracion}  </Grid.Row> 
            </div>
          : null}
          { this.state.plataforma.length > 0 ?//*************************************************************Plataforma
            <div>
              <hr style={ estilo } />
              <h5>Plataformas</h5>
              <Grid.Row>{ this.state.plataforma } </Grid.Row>  
            </div>
          :null}
          { this.state.gps.length > 0 ?//********************************************************************GPS
            <div>
              <hr style={ estilo } />
              <h5>GPS</h5>        
              <Grid.Row>{this.state.gps} </Grid.Row>
            </div>
          :null}
          { this.state.alertas.length > 0 ?//****************************************************************Alertas
            <div>
              <hr style={ estilo } />
              <h5>Alertas</h5>
              <Grid.Row>{this.state.alertas}</Grid.Row>
            </div>
          :null}
          { this.state.catalogos.length > 0 ?//**************************************************************Catalogos
            <div>
              <hr style={ estilo } />
              <h5>Catalogos</h5>
              <Grid.Row>{this.state.catalogos}
              </Grid.Row>
            </div>
          :null}
          { this.state.unidades.length > 0 ?//***************************************************************Unidades
            <div>
              <hr style={ estilo } />
              <h5>Unidades</h5>
              <Grid.Row>{this.state.unidades}</Grid.Row> 
            </div>
          :null}          
        </div>
      </SiteWrapper>
    );
  }
}
export default AdminstracionView;

