import React, { Component } from 'react';
import { Icon, Form, Button, Table } from 'tabler-react';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import ModalLay from '../../components/ModalLay/ModalLay';
import DatosMovimientoGpsForm from './DatosMovimientoGpsForm';
import MovimientoIncidencias from './MovimientoIncidencias';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import { TablePageable } from '../../Helpers';
import { MovimientoGpsEvidenciaModal } from '../../components/MovimientoGpsEvidenciaModal/MovimientoGpsEvidenciaModal';
import moment from 'moment';
import WhatsAppChatBot from './WhatsAppChatBot';
var user = JSON.parse(sessionStorage.getItem('usuario'));
var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
//console.log("Valores "+JSON.stringify(user.id));

class MovimientoGpsForm extends Component {
  constructor() {
    super();
    this.state = {
      plataformas: [],
      plataforma: { value: 0, label: 'Seleciona una plataforma', data: null },
      movimientoSinGps: [],
      hiddenModals: false,
      asignados: false,
      incidenciasAll: [],
      columnas: [],
      localidades: {},
      lastUpdated: '',
    };
    this.content = [];
  }

  componentWillMount() {
    let columnasTemp = [
      { columna: 'idMovimiento', label: 'ID MOVIMIENTO' },
      {
        columna: 'creationDate',
        label: 'FECHA DE CREACIÓN',
        valor: (e) => moment(e.creationDate).format('YYYY-MM-DD HH:mm:ss'),
        form: (e) => moment(e.creationDate).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        columna: 'cita',
        label: 'CITA',
        valor: (e) =>
          e.cita ? moment(e.cita).format('YYYY-MM-DD HH:mm:ss') : '',
        form: (e) =>
          e.cita ? moment(e.cita).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        columna: 'citaColocacionGps',
        label: 'CITA DE COLOCACIÓN GPS',
        valor: (e) => e.citaColocacionGps ? moment(e.citaColocacionGps).format('YYYY-MM-DD HH:mm:ss') : '',
        form: (e) => e.citaColocacionGps ? moment(e.citaColocacionGps).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        columna: 'colocacionFecha',
        label: 'FECHA DE COLOCACIÓN',
        valor: (e) => e.colocacionFecha ? moment(e.colocacionFecha).format('YYYY-MM-DD HH:mm:ss') : '',
        form: (e) => e.colocacionFecha ? moment(e.colocacionFecha).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        columna: 'idOperacionEstatus',
        label: 'ESTATUS OPERACIÓN',
        valor: (e) => {
          if (e.idOperacionEstatus === 1) {
            return 'SIN GPS';
          } else if (e.idOperacionEstatus === 2) {
            return 'EN RUTA';
          }
        },
        form: (e) => {
          if (e.idOperacionEstatus === 1) {
            return 'SIN GPS';
          } else if (e.idOperacionEstatus === 2) {
            return 'EN RUTA';
          }
        },
      },
      {
        columna: 'gps',
        label: 'GPS',
        valor: (e) =>
          e.gps
            ? e.gps
            : e.esfull
            ? 'full con: ' + e.esfull
            : e.programado
            ? 'programado'
            : '',
        form: (e) =>
          e.gps
            ? e.gps
            : e.esfull
            ? 'full con: ' + e.esfull
            : e.programado
            ? 'programado'
            : '',
      },
      { columna: 'imei', label: 'IMEI' },
      { columna: 'po', label: 'PO' },
      { columna: 'booking', label: 'BOOKING' },
      { columna: 'contenedor', label: 'CONTENEDOR' },
      { columna: 'transportista', label: 'TRANSPORTISTA' },
      { columna: 'cliente', label: 'CLIENTE' },
      { columna: 'movimientoTipo', label: 'TIPO DE MOVIMIENTO' },
      { columna: 'corredor', label: 'CORREDOR' },
      {
        columna: 'localidad',
        label: 'LOCALIDAD',
        valor: (e) =>
          this.state.localidades[e.idLocalidad]
            ? this.state.localidades[e.idLocalidad].nombre
            : '',
        form: (e) =>
          this.state.localidades[e.idLocalidad]
            ? this.state.localidades[e.idLocalidad].nombre
            : '',
      },
    ];
    columnasTemp.push({
      columna: 'add',
      label: 'AGREGAR GPS',
      form: (e) => (
        <ModalLay
          tamanio={'99%'}
          title="GPS Movimiento"
          name={
            <Button pill color="azure">
              <i class="fa fa-plus"></i>
            </Button>
          }
          formulario={DatosMovimientoGpsForm}
          datosForm={{
            movimientos: this.state.movimientoSinGps,
            movimiento: e,
            idMovimiento: e.idMovimiento,
            userId: user.id,
            usuario: user.persona,
            hiddenmodal: this.hiddenmodal,
            plataformaid: this.state.plataforma.value,
            refetch: this.refrescar,
          }}
        />
      ),
    });
    columnasTemp.push({
      columna: 'inc',
      label: 'INCIDENCIA',
      form: (e) => (
        <ModalLay
          tamanio={600}
          title="Nueva incidencia"
          name={
            <Button pill size="sm" outline color="primary">
              <Icon name="plus" />
            </Button>
          }
          formulario={MovimientoIncidencias}
          datosForm={{
            accion: 'nuevo',
            incidencias: this.state.incidenciasAll,
            idusuario: user.id,
            idmovimiento: e.idMovimiento,
            getfuntion: this.getfuntion,
            stor: this.props,
            registro: e,
          }}
        />
      ),
    });
    columnasTemp.push({
      columna: 'evidencias',
      label: 'Evidencias',
      form: (e) => (
        <ModalLay
          tamanio={1200}
          title="Evidencias colocación"
          name={
            <span
              style={{
                color: '#63e67b',
                'font-size': '200%',
              }}
            >
              <i className="fa fa-picture-o" />
            </span>
          }
          formulario={MovimientoGpsEvidenciaModal}
          datosForm={{ movimiento_id: e.idMovimiento, gps: e.gps }}
        />
      ),
    });
    columnasTemp.push({
      columna: 'chat',
      label: 'Chat',
      form: (e) => (
        <ModalLay
          tamanio={"95%"}
          title="Chat"
          name={
            <span
              style={{
                color: '#63e67b',
                'font-size': '200%',
              }}
            >
              <i className="fa fa-comments-o" />
            </span>
          }
          formulario={WhatsAppChatBot}
          datosForm={{ movimiento: e }}
        />
      ),
    });
    columnasTemp.push({
      columna: 'reset',
      label: 'Resetear',
      form: (e) => (
        <Button onClick={() => this.reset(e.idMovimiento)} color="danger">
          resetear
        </Button>
      ),
    });

    this.setState({ columnas: columnasTemp });

    Crud_Catalogos(
      'globalMobile/general_movimientos_sin_gps_total',
      'movimientosgps',
      'get',
      '',
      [],
      '',
      this.state.plataformas,
      '',
      []
    )
      .then((returnVal) => {
        let plataformasTemp = [
          { value: 0, label: 'Seleciona una plataforma', data: null },
        ];
        for (let p of returnVal) {
          plataformasTemp.push({
            value: p.idPlataformas,
            label: p.plataforma + '(' + p.totalPorPlataformas + ')',
            data: p,
          });
        }
        this.setState({ plataformas: plataformasTemp });
      })
      .catch((err) => {
        console.log(err);
      });

    this.getfuntion('get', [], '', 'incidencias', 'incidenciasAll');
    this.getfuntion('get', [], '', 'localidades', 'localidades');
  }

  componentDidMount() {
    this.setState({ lastUpdated: new Date() });
  }

  //Función para actualizar la fecha de la última actualización
  updateLastUpdated = () => {
    this.setState({ lastUpdated: new Date() });
  };

  onChangePlataforma = (event) => {
    this.setState({ plataforma: event });
    for (let item of currentUser) {
      if (item.plataforma.id === parseInt(event.value)) {
        if (!item.plataforma.zonaHoraria) {
          item.plataforma.zonaHoraria = '';
        }
        if (
          //!item.plataforma.zonaHoraria &&
          //item.plataforma.zonaHoraria.toUpperCase().includes("MEX") &&
          item.perfil.movimientosBy === 4
        ) {
          Crud_Catalogos(
            'globalMobile/movimientos_sin_gps_x_plataforma_localidades/' +
              event.value +
              '/' +
              user.id,
            'movimientosgps',
            'get',
            '',
            [],
            '',
            this.state.plataformas,
            '',
            []
          )
            .then((returnVal) => {
              this.setState({ movimientoSinGps: returnVal });
            })
            .catch((err) => {
              if (err.response) {
                console.log(err);
              } else {
              }
            });
        } else {
          Crud_Catalogos(
            'globalMobile/movimientos_sin_gps_x_plataforma/' +
              event.value +
              '/' +
              user.id,
            'movimientosgps',
            'get',
            '',
            [],
            '',
            this.state.plataformas,
            '',
            []
          )
            .then((returnVal) => {
              this.setState({ movimientoSinGps: returnVal });
              this.updateLastUpdated();
            })
            .catch((err) => {
              if (err.response) {
                console.log(err);
              } else {
              }
            });
        }
      }
    }
  };

  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };

  getfuntion = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = 'cliente',
    stateVar = 'clientes',
    hiddenModl = ''
  ) => {
    return Crud_Catalogos(
      catalogo,
      'movimientoGps',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case 'get':
            {
              if (stateVar === 'movimiento') {
                return returnVal;
              }
              if (stateVar === 'movimientosPatio') {
                return returnVal;
              }
              if (stateVar === 'movimientosPatioF') {
                return returnVal;
              }
              if (stateVar === 'incidenciasAll') {
                this.setState({ incidenciasAll: returnVal });
              }
              if (stateVar === 'localidades') {
                let localidadesTemp = {};
                for (let l of returnVal) {
                  localidadesTemp[l.id] = l;
                }
                this.setState({ localidades: localidadesTemp });
              }
            }
            break;
          case 'post':
            {
              console.log(catalogo, stateVar, metodo);
              if (stateVar === 'removerActivo') {
                if (returnVal.OK) {
                  NotificationManager.info('GPS: ' + obj.gps, 'Gps removido');
                }
                if (returnVal.Error) {
                  NotificationManager.error('GPS: ' + obj.gps, returnVal.Error);
                }
                return returnVal;
              }
            }
            break;
          case 'put':
            {
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  refrescar = () => {
    if (!this.state.plataforma.value) return false;
    Crud_Catalogos(
      'globalMobile/movimientos_sin_gps_x_plataforma/' +
        this.state.plataforma.value +
        '/' +
        user.id,
      'movimientosgps',
      'get',
      '',
      [],
      '',
      '',
      '',
      []
    )
      .then((returnVal) => {
        this.setState({ movimientoSinGps: returnVal });
        this.updateLastUpdated();
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
        }
      });
  };
  reset = (id) => {
    let movimientoT = [];
    let mPatio1 = [];
    this.getfuntion(
      'get',
      [],
      '',
      'navieraMovimientos/' + id,
      'movimiento'
    ).then((movNav) => {
      movimientoT = movNav;
      //obtener full
      this.getfuntion(
        'get',
        [],
        '',
        'movimientosPatio/idMovimiento/' + id,
        'movimientosPatio'
      ).then((mPatio) => {
        mPatio1 = mPatio;
        if (mPatio.esFull) {
          this.getfuntion(
            'get',
            [],
            '',
            'navieraMovimientos/' + mPatio1.esFull,
            'movimiento'
          ).then((movNavFull) => {
            if (movNavFull) {
              for (let item of movNavFull.movimientoGps) {
                //remover gps de full
                var recGps = {
                  gps: item.gps.id,
                  plataforma: movNavFull.idPlataforma.id,
                  estatus: 1,
                  idMovimiento: item.idMovimiento,
                };
                this.getfuntion(
                  'post',
                  recGps,
                  '',
                  'gps/remover_activo',
                  'removerActivo'
                ).then((rmGps) => {});
              }
              this.getfuntion(
                'put',
                [],
                '',
                'navieraMovimientos/movimiento/' +
                  movNavFull.id +
                  '/operacionestatus/1',
                'navieraMovimientos'
              );
              //reset patio
              this.getfuntion(
                'get',
                [],
                '',
                'movimientosPatio/idMovimiento/' + movNavFull.id,
                'movimientosPatioF'
              ).then((mPatioFull) => {
                this.getfuntion(
                  'delete',
                  '',
                  mPatioFull.id,
                  'movimientosPatio',
                  'movimientosPatio'
                );
              });
            }
          });
        }
        //remover patio
        this.getfuntion(
          'delete',
          '',
          mPatio1.id,
          'movimientosPatio',
          'movimientosPatio'
        );
      });
      //remover gps
      for (let item of movimientoT.movimientoGps) {
        this.getfuntion(
          'put',
          [],
          '',
          'navieraMovimientos/movimiento/' +
            item.idMovimiento +
            '/operacionestatus/1',
          'navieraMovimientos'
        );

        var recGps = {
          gps: item.gps.id,
          plataforma: movimientoT.idPlataforma.id,
          estatus: 1,
          idMovimiento: item.idMovimiento,
        };
        this.getfuntion(
          'post',
          recGps,
          '',
          'gps/remover_activo',
          'removerActivo'
        ).then((rmGps) => {
          this.refrescar();
        });
      }
    });
  };
  removerGps = (gps, mov) => {
    var recGps = {
      gps: gps.gps.id,
      plataforma: mov.idPlataforma.id,
      estatus: 1,
      idMovimiento: gps.idMovimiento,
    };
    confirmAlert({
      title: 'Atención',
      message:
        '¿Esta seguro de remover el gps: ' +
        gps.gps.nombre +
        '( del mov: ' +
        gps.idMovimiento +
        ') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => {
            this.getfuntion(
              'post',
              recGps,
              '',
              'gps/remover_activo',
              'removerActivo'
            );
          },
        },
        {
          label: 'Cancelar',
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };
  render() {
    return (
      <>
        <div class="row m-3">
          <div className="col-4">
            <Form.Group label="Selecciona la Plataforma">
              <Select
                value={this.state.plataforma}
                onChange={(e) => this.onChangePlataforma(e)}
                options={this.state.plataformas}
              />
            </Form.Group>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <Form.Group label="Refrescar">
              <Button color="primary" onClick={() => this.refrescar()}>
                <Icon name="refresh-cw" /> Última actualización:{' '}
                {this.state.lastUpdated
                  ? this.state.lastUpdated.toLocaleString()
                  : 'N/A'}
              </Button>
            </Form.Group>
          </div>
        </div>

        <div class="row mx-3">
          <div className="col col-12 col-sm-12 col-md-12 ">
            <TablePageable
              titulo={
                <h3>
                  <Form.Switch
                    type="Checkbox"
                    checked={this.state.asignados}
                    onChange={(e) =>
                      this.setState({ asignados: e.target.checked })
                    }
                  />
                  {this.state.asignados
                    ? 'Movimientos con GPS asignados'
                    : 'Movimientos a asignar GPS'}
                </h3>
              }
              lista={this.state.movimientoSinGps.filter((word) =>
                this.state.asignados
                  ? word.gps || (!word.gps && word.esfull)
                  : !word.gps && !word.esfull
              )}
              columnas={this.state.columnas}
              id="idMovimiento"
              //selected={() => { }}
              csvname={
                'Movimientos ' +
                (this.state.asignados ? 'con GPS asignados' : 'a asignar GPS')
              }
              paginacion={true}
              elementosPorPagina={15}
              resaltarFuncion={(e) =>
                e.gps ||
                e.programado ||
                (e.cita && new Date(e.cita).getTime() < new Date().getTime())
              }
              resaltarColor={(e) => {
                return e.gps
                  ? 'rgba(95, 231, 50, 0.30)'
                  : e.programado
                  ? 'rgba(38, 229, 242, 0.3)'
                  : new Date(e.cita).getTime() > new Date().getTime()
                  ? 'rgba(255, 255, 255, 0.30)'
                  : 'rgba(255, 55, 55, 0.30)';
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default MovimientoGpsForm;
