export const UPDATE_MENSAJE= "UPDATE_MENSAJE";
export const DASHBOARD_USUARIOS= "DASHBOARD_USUARIOS";

/**
 * CATALOGOS
 */
export const ElEMENTOS_ALERTASCATEGORIAS_IMPUT= "ELEMENTOS_ALERTASCATEGORIAS_IMPUT";
export const ElEMENTOS_ALERTASTIPOS_IMPUT= "ELEMENTOS_ALERTASTIPOS_IMPUT";

export const ElEMENTOS_SERVICE_FAILURE_IMPUT= "ElEMENTOS_SERVICE_FAILURE_IMPUT";
export const ElEMENTOS_BLOQUES_IMPUT= "ELEMENTOS_BLOQUES_IMPUT";
export const ElEMENTOS_DESTINOS_IMPUT= "ELEMENTOS_DESTINOS_IMPUT";

export const ElEMENTOS_INFORMES_IMPUT= "ELEMENTOS_INFORMES_IMPUT";
export const ElEMENTOS_INFORMESFUNCIONALIDADES_IMPUT= "ELEMENTOS_INFORMESFUNCIONALIDADES_IMPUT";
export const ELEMENTOS_CATEGORIAS_IMPUT= "ELEMENTOS_CATEGORIAS_IMPUT";
export const ELEMENTOS_CLIENTES_IMPUT= "ELEMENTOS_CLIENTES_IMPUT";
export const ELEMENTOS_CLIENTESCONTACTOS_IMPUT = "ELEMENTOS_CLIENTESCONTACTOS_IMPUT";
export const ELEMENTOS_OPERACIONESCLIENTES_IMPUT = "ELEMENTOS_OPERACIONESCLIENTES_IMPUT";

export const ELEMENTOS_CORREDORES_IMPUT= "ELEMENTOS_CORREDORES_IMPUT";
export const ELEMENTOS_PATIOSVACIOS_IMPUT= "ELEMENTOS_PATIOSVACIOS_IMPUT";
export const ELEMENTOS_LOCALIDADES_IMPUT= "ELEMENTOS_LOCALIDADES_IMPUT";
export const ELEMENTOS_ETIQUETAS_IMPUT= "ELEMENTOS_ETIQUETAS_IMPUT";
export const ELEMENTOS_EVENTOS_IMPUT= "ELEMENTOS_EVENTOS_IMPUT";
export const ELEMENTOS_INCIDENCIASTIPO_IMPUT= "ELEMENTOS_INCIDENCIASTIPO_IMPUT";
export const ELEMENTOS_PERSONAS_IMPUT= "ELEMENTOS_PERSONAS_IMPUT";
export const ELEMENTOS_PLATAFORMAS_IMPUT = "ELEMENTOS_PLATAFORMAS_IMPUT";
export const ELEMENTOS_PLATAFORMASTIPO_IMPUT= "ELEMENTOS_PLATAFORMASTIPO_IMPUT";
export const ELEMENTOS_REGLAS_IMPUT= "ELEMENTOS_REGLAS_IMPUT";
export const ELEMENTOS_PRIVILEGIOS_IMPUT= "ELEMENTOS_PRIVILEGIOS_IMPUT";
export const ElEMENTOS_USUARIOS_IMPUT= "ElEMENTOS_USUARIOS_IMPUT";
export const ElEMENTOS_USUARIOPERFIL_IMPUT= "ElEMENTOS_USUARIOPERFIL_IMPUT";
export const ELEMENTOS_INCIDENCIAS_IMPUT= "ELEMENTOS_INCIDENCIAS_IMPUT";
export const ELEMENTOS_PERFILES_IMPUT = "ELEMENTOS_PERFILES_IMPUT";
export const ELEMENTOS_MOVIMIENTOSBY_IMPUT = "ELEMENTOS_MOVIMIENTOSBY_IMPUT";


export const ELEMENTOS_GPS_IMPUT = "ELEMENTOS_GPS_IMPUT";
export const ELEMENTOS_GPSFIJOS_IMPUT = "ELEMENTOS_GPSFIJOS_IMPUT";
export const ELEMENTOS_GPSTIPOCOLOCACION_IMPUT = "ElEMENTOS_GPSTIPOCOLOCACION_IMPUT";
export const ELEMENTOS_GPSESTATUS_IMPUT= "ELEMENTOS_GPSESTATUS_IMPUT";
export const ELEMENTOS_GPSHARDWARE_IMPUT = "ELEMENTOS_GPSHARDWARE_IMPUT";

export const ELEMENTOS_FUNCIONALIDADES_IMPUT = "ELEMENTOS_FUNCIONALIDADES_IMPUT";
export const ELEMENTOS_TRANSPORTISTAS_IMPUT = "ELEMENTOS_TRANSPORTISTAS_IMPUT";
export const ELEMENTOS_RUTASESTATUS_IMPUT = "ELEMENTOS_RUTASESTATUS_IMPUT";
export const ELEMENTOS_OTD_IMPUT = "ElEMENTOS_OTD_IMPUT";
export const ELEMENTOS_NAVIERAS_IMPUT = "ElEMENTOS_NAVIERAS_IMPUT";
export const ELEMENTOS_ACUMULADORES_IMPUT = "ELEMENTOS_ACUMULADORES_IMPUT";
export const ELEMENTOS_BITACORAMONITOREO_IMPUT = "ELEMENTOS_BITACORAMONITOREO_IMPUT";
export const ELEMENTOS_MOVIMIENTOSTIPO_IMPUT = "ELEMENTOS_MOVIMIENTOSTIPO_IMPUT";
export const ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT = "ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT";
export const ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT = "ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT";
export const ELEMENTOS_SALASMONITOREO_IMPUT = "ELEMENTOS_SALASMONITOREO_IMPUT";

export const ELEMENTOS_OPERACIONESESTATUS_IMPUT = "ElEMENTOS_OPERACIONESESTATUS_IMPUT";
export const ELEMENTOS_OPERACIONESTIPO_IMPUT = "ELEMENTOS_OPERACIONESTIPO_IMPUT";
export const ELEMENTOS_OPERADORES_IMPUT = "ELEMENTOS_OPERADORES_IMPUT";
export const ELEMENTOS_ETACONFIGURACION_IMPUT = "ELEMENTOS_ETACONFIGURACION_IMPUT";

export const ElEMENTOS_UNIDADES_IMPUT               = "ELEMENTOS_UNIDADES_IMPUT";
export const ElEMENTOS_UNIDADES_NAVIERAS_IMPUT            = "ElEMENTOS_UNIDADES_NAVIERAS_IMPUT";
export const ELEMENTOS_UNIDADES_MARCAS_IMPUT        = "ELEMENTOS_UNIDADES_MARCAS_IMPUT";
export const ElEMENTOS_UNIDADTIPO_IMPUT             = "ELEMENTOS_UNIDADTIPO_IMPUT";
export const ElEMENTOS_UNIDADELEMENTO_IMPUT         = "ELEMENTOS_UNIDADELEMENTO_IMPUT";
export const ElEMENTOS_UNIDADESTATUSOPERACION_IMPUT = "ELEMENTOS_UNIDADESTATUSOPERACION_IMPUT";
export const ElEMENTOS_UNIDADTIPOCOLOCACION_IMPUT   = "ELEMENTOS_UNIDADTIPOCOLOCACION_IMPUT";
export const ELEMENTOS_ALERTASUNIDADES_IMPUT        = "ELEMENTOS_ALERTASUNIDADES_IMPUT";
export const ELEMENTOS_CLIENTES_NAVIEROS_IMPUT      = "ELEMENTOS_CLIENTES_NAVIEROS_IMPUT";
export const ELEMENTOS_MODALIDADES_IMPUT            = "ELEMENTOS_MODALIDADES_IMPUT";
export const ELEMENTOS_ORIGENCATALOGOMABE_IMPUT     = "ELEMENTOS_ORIGENCATALOGOMABE_IMPUT";