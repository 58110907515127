import React, { Component } from 'react';
import Template from './Template.react';
import ModalLayout from '../../../../../components/ModalLayout.react';
import TabsLayout from '../../../../../components/TabsLayout.react';
import {
  Container,
  Grid,
  Card,
  Button,
  Form,
  Icon,
  Dimmer,
  List,
  Table,
  GalleryCard,
} from 'tabler-react';
import Select from 'react-select';
import {
  Crud_Catalogos,
  Crud_error,
} from '../../../../../herramientas/Crud_Catalogos';
import LoadPicture from './LoadPicture';
import * as conf from '../../../../../config/config';
//import temadark from "../../public/images/temadark.jpg";
import '../hexToRgbA';
import { hexToRgbA } from '../hexToRgbA';
import RgbATohex from '../RgbATohex';
import './file.css';

var elementos = [
  { value: 0, label: 'Selecciona un elemento' },
  { value: 1, label: 'Header' },
  { value: 2, label: 'Body' },
  { value: 3, label: 'Footer' },
];
var user = JSON.parse(sessionStorage.getItem('currentUser'));

var reader = new FileReader();
var imageIndex = '';
var plantillaModif = '';
var upImage = null;

var plantillacopy = '';
var correoPlantilas = [];
class Generador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenModal: false,
      estilos: { value: 0, label: 'Selecciona  un estilo' },
      elemento: { value: 0, label: 'Selecciona un elemento' },
      Lista: [],
      currentTap: 0,
      estilosArray: [],
      plantilla: '',
      texto: [],
      campos: [],
      search: '',
      images: [],
      loading: true,
      correoCampos: [],
      correoColumnas: [],
      correoPlantilla: [],
      cidname: '',
      cidViewfilename: 'Selecciona una imagen',
      movimientoTipos: [],
      movimientoTipo: { value: 0, label: 'Seleccione un plantilla' },
    };
    this.etiquetas = [
      '<!',
      '<html',
      '<!--',
      '<meta',
      '<link',
      '<script',
      '</',
      '<style>',
      '<head>',
    ];
    this.etiqArray = [];
    this.estilos = [];
    this.lista = [];
    this.texto = [];
    this.campos = [];
    this.images = [];
    this.correoColumnas = [];
    this.camposLogisticos = {};
    this.rowsCampos = [];
    this.camposLogisticosAll = [];
  }
  componentWillMount = () => {
    if (
      this.props.Plataforma.plataformaTipo.nombre.toUpperCase() === 'NAVIERA'
    ) {
    }
    if (
      this.props.Plataforma.plataformaTipo.nombre.toUpperCase() === 'TERRESTRE'
    ) {
    }
    let plataformaTipoTemp =
      this.props.Plataforma.plataformaTipo.id === 3
        ? 1
        : this.props.Plataforma.plataformaTipo.id;

    this.getfuntion(
      'get',
      '',
      '',
      'correoCampos/plataformaTipo/' + plataformaTipoTemp,
      'correoCampos'
    );
    this.getfuntion(
      'get',
      '',
      '',
      'camposLogisticos/plataforma/' + this.props.Plataforma.id,
      'camposLogisticos'
    );
  };
  componentDidMount = () => {
    plantilla = plantilla.replace('ñ', '&ntilde');
    plantilla = plantilla.replace('á', '&aacute');
    plantilla = plantilla.replace('Á', '&aacute');
    plantilla = plantilla.replace('é', '&eacute');
    plantilla = plantilla.replace('É', '&Eacute');
    plantilla = plantilla.replace('í', '&iacute');
    plantilla = plantilla.replace('Í', '&Iacute');
    plantilla = plantilla.replace('ó', '&oacute');
    plantilla = plantilla.replace('Ó', '&Oacute');
    plantilla = plantilla.replace('ú', '&uacute');
    plantilla = plantilla.replace('Ú', '&Uacute');
    this.readPlantilla();
    this.writePlantilla('mount');
    this.lista.push({ value: 0, label: 'Selecciona  un estilo' });
    for (let i = 0; i < this.estilos.length; i++) {
      this.lista.push({ value: i + 1, label: this.estilos[i].tagEstilo });
    }
    this.setState({ Lista: this.lista });
    this.setState({ estilosArray: this.estilos });
  };
  componentDidUpdate = () => {
    if (
      this.state.correoPlantilla !== this.props.correoPlantillas &&
      this.props.correoPlantillas
    ) {
      this.setState({ correoPlantilla: this.props.correoPlantillas });
      this.getfuntion(
        'get',
        '',
        '',
        'correoplantillasimages/correoplantilla/' +
          this.props.correoPlantillas.id,
        'images'
      );
      this.getfuntion(
        'get',
        '',
        '',
        'correoColumnas/correoplantilla/' + this.props.correoPlantillas.id,
        'correoColumnas'
      );
    } /*
    if( this.state.correoPlantilla !== this.props.correoPlantillas ){
      this.setState({ movimientoTipo: { value: 0, label: "Seleccione un plantilla" } });
    } */
    if (
      plantilla !== this.props.texto &&
      plantillacopy !== this.props.texto /*&& this.props.texto !==""*/
    ) {
      plantilla = this.props.texto;
      plantillacopy = this.props.texto;
      try {
        this.writePlantilla();
      } catch (error) {
        let errorPage =
          '<!DOCTYPE html><html><title>Error</title><style>.body{ background-color: rgba(113, 211, 244, 1);color: black;font-size: 12px;}' +
          '.contenedor { background-color: rgba(138, 144, 240, 0.15);color: black;font-size: 25px; text-align: center; } .logo{ float: center; width: 20%; margin: 10%; } </style>' +
          '<body class="body"><div class="contenedor"><img src="http://one.skyangel.com.mx/images/allone.svg" name="logoheader" class="logo" alt="logo"><h1>Lo sentimos</h1>' +
          '<p>El codigo de la plantilla no se a podido leer.</p></div></body></html>';
        plantilla = errorPage;
        try {
          this.writePlantilla();
        } catch (error) {}
      }
    }
  };
  getfuntion = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = '',
    stateVar = '',
    hiddenModl = ''
  ) => {
    return Crud_Catalogos(
      catalogo,
      'plataformas',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case 'get':
            {
              if (stateVar === 'correoCampos') {
                if (returnVal[0].id) {
                  returnVal.map((campo, index) => {
                    if (this.isCheckedCampo(campo.id)) {
                      this.campos.push({
                        campo: campo.campoEtiqueta,
                        valor: 1,
                        index: index,
                      });
                    } else {
                      this.campos.push({
                        campo: campo.campoEtiqueta,
                        valor: 0,
                        index: index,
                      });
                    }
                  });
                  this.setState({ correoCampos: returnVal });
                }
              }
              if (stateVar === 'correoColumnas') {
                this.setState({ correoColumnas: returnVal });
              }
              if (stateVar === 'images') {
                returnVal.map((img, index) => {
                  //this.images.push({ name: img.cid, image: require(conf.api_raiz+"imagenes/"+img.filename) });
                  document.querySelector('img[name="' + img.cid + '"]').src =
                    conf.api_raiz + 'imagenes/' + img.filename;
                });
                this.setState({ images: returnVal });
              }
              if (stateVar === 'camposLogisticos') {
                this.camposLogisticosAll = returnVal;
                let mTipoTemp = {};
                for (let item of returnVal) {
                  mTipoTemp[item.idMovimientoTipo] = item.idMovimientoTipo;
                  if (!this.camposLogisticos[item.idMovimientoTipo]) {
                    this.camposLogisticos[item.idMovimientoTipo] = {};
                  }
                  this.camposLogisticos[item.idMovimientoTipo][
                    '_' + item.nombreCampo
                  ] = item;
                }
                let movimientoTipos = [];
                Object.keys(mTipoTemp).forEach((key) => {
                  let tipo = parseInt(key, 10);
                  if (tipo == 1 || tipo == 3) {
                    movimientoTipos.push({ value: tipo, label: 'Importación' });
                    this.setState({
                      movimientoTipo: { value: tipo, label: 'Importación' },
                    });
                  }
                  if (tipo == 2 || tipo == 4) {
                    movimientoTipos.push({ value: tipo, label: 'Exportación' });
                    this.setState({
                      movimientoTipo: { value: tipo, label: 'Exportación' },
                    });
                  }
                  this.setState({ movimientoTipos: movimientoTipos });
                });
              }
            }
            break;
          case 'post':
            {
              if (stateVar === 'correoColumnas') {
                this.correoColumnas = this.state.correoColumnas;
                this.correoColumnas.push(returnVal);
                this.setState({ correoColumnas: this.correoColumnas });

                var addCampos = document.querySelector('tbody[id="campos"]');
                if (addCampos) {
                  var Ucampos = '';
                  if (this.props.correoPlantillas.vertical) {
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos +
                          '<tr><th>' +
                          cam.campoEtiqueta +
                          '</th><td>' +
                          cam.campoEtiqueta +
                          '</td></tr>';
                      }
                    });
                  } else {
                    Ucampos = Ucampos + '<tr>';
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos + '<th>' + cam.campoEtiqueta + '</th>';
                      }
                    });
                    Ucampos = Ucampos + '</tr><tr>';
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos + '<td>' + cam.campoEtiqueta + '</td>';
                      }
                    });
                    Ucampos = Ucampos + '</tr>';
                  }
                  addCampos.innerHTML = Ucampos;
                } else {
                  alert('Err: No se cargaron los campos');
                }
                this.correoColumnas = [];
              }
              if (stateVar === 'images') {
                let temImages = this.state.images;
                temImages.map((img, index) => {
                  if (img.cid == returnVal.cid) {
                    img = returnVal;
                  }
                  document.querySelector('img[name="' + img.cid + '"]').src =
                    conf.api_raiz + 'imagenes/' + img.filename;
                });
                this.setState({ images: temImages });
              }
              if (stateVar === 'imageNew') {
                if (!this.state.images.length) {
                  let temImages = [];
                  temImages.push(returnVal);
                  temImages.map(
                    (img, index) =>
                      (document.querySelector(
                        'img[name="' + img.cid + '"]'
                      ).src = conf.api_raiz + 'imagenes/' + img.filename)
                  );
                  this.setState({ images: temImages });
                } else {
                  let temImages = this.state.images;
                  let found = false;
                  temImages.map((img, index) => {
                    if (img.cid == returnVal.cid) {
                      img = returnVal;
                      found = true;
                    }
                    document.querySelector('img[name="' + img.cid + '"]').src =
                      conf.api_raiz + 'imagenes/' + img.filename;
                  });
                  if (!found) {
                    temImages.push(returnVal);
                    document.querySelector(
                      'img[name="' + returnVal.cid + '"]'
                    ).src = conf.api_raiz + 'imagenes/' + returnVal.filename;
                  }
                  this.setState({ images: temImages });
                }
              }
            }
            break;
          case 'delete':
            {
              if (stateVar) {
                this.correoColumnas = this.state.correoColumnas;
                var idCampo, idPlantilla;
                for (let i = 0; i < id.length; i++) {
                  if (id.charAt(i) !== '/') {
                    idCampo = idCampo = id.charAt(i);
                  }
                  if (id.charAt(i) === '/') {
                    idPlantilla = id.substr(i + 1, id.length - 1);
                    break;
                  }
                }

                for (let item = 0; item < this.correoColumnas.length; item++) {
                  if (
                    this.correoColumnas[item].idCorreoCampos == idCampo &&
                    this.correoColumnas[item].idCorreoPlantilla == idPlantilla
                  ) {
                    this.correoColumnas.splice(item, 1);
                    //this.correoColumnas= this.correoColumnas;
                  }
                }
                this.setState({ correoColumnas: this.correoColumnas });

                var addCampos = document.querySelector('tbody[id="campos"]');
                if (addCampos) {
                  var Ucampos = '';
                  if (this.props.correoPlantillas.vertical) {
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos +
                          '<tr><th>' +
                          cam.campoEtiqueta +
                          '</th><td>' +
                          cam.campoEtiqueta +
                          '</td></tr>';
                      }
                    });
                  } else {
                    Ucampos = Ucampos + '<tr>';
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos + '<th>' + cam.campoEtiqueta + '</th>';
                      }
                    });
                    Ucampos = Ucampos + '</tr><tr>';
                    this.state.correoCampos.map((cam, index) => {
                      if (this.isCheckedCampo(cam.id)) {
                        Ucampos =
                          Ucampos + '<td>' + cam.campoEtiqueta + '</td>';
                      }
                    });
                    Ucampos = Ucampos + '</tr>';
                  }
                  addCampos.innerHTML = Ucampos;
                } else {
                  alert('Err: No se cargaron los campos');
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log('Error desconocido ....');
          console.log(err);
        }
      });
  };

  readPlantilla = () => {
    this.setState({ Lista: [] });
    this.lista = [];
    this.estilos = [];

    var temeEiqueta = '';
    var coppying = false;

    var estilo = false;
    var tagEstilo = '';
    var temEstilo = '';
    var arrayEstilo = [];
    var estilovalor = '';
    var arrayvalor = [];
    var coppytag = false;
    var coppyEstyle = false;
    var coppyvalor = false;
    for (var i = 0; i < plantilla.length; i++) {
      if (plantilla.charAt(i) === '<' && !coppying) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
        coppying = true;
      }
      if (
        plantilla.charAt(i) !== '<' &&
        plantilla.charAt(i) !== '>' &&
        coppying
      ) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
      }
      if (plantilla.charAt(i) === '>' && coppying) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
        coppying = false;
        if (temeEiqueta == '<style>') {
          estilo = true;
        }
        if (temeEiqueta == '</style>') {
          estilo = false;
        }
        /*
        if(this.validEtiqueta(temeEiqueta)){
          if(this.etiqArray.filter(eq => eq.temeEiqueta == temeEiqueta).length > 0 ){
          }else{
            this.etiqArray.push({ temeEiqueta });
          }
        }
        */

        temeEiqueta = '';
      }

      if (estilo) {
        if (
          estilo &&
          plantilla.charAt(i) !== '{' &&
          estilo &&
          plantilla.charAt(i) !== '>' &&
          !coppytag
        ) {
          tagEstilo = tagEstilo + plantilla.charAt(i);
        }
        if (estilo && plantilla.charAt(i) == '{' && !coppytag) {
          //this.estilos.push({temeEiqueta:[]});
          coppytag = true;
        }
        if (plantilla.charAt(i) == ':') {
          coppyEstyle = true;
          coppyvalor = false;
          arrayEstilo.push(temEstilo);
          temEstilo = '';
        }
        //save
        if (plantilla.charAt(i) == '}') {
          coppytag = false;
          this.estilos.push({ tagEstilo, arrayEstilo, arrayvalor });
          arrayEstilo = [];
          arrayvalor = [];
          tagEstilo = '';
        }
        if (plantilla.charAt(i) == ';') {
          coppyEstyle = false;
          coppyvalor = true;
          arrayvalor.push(estilovalor);
          estilovalor = '';
        }

        if (
          !coppyEstyle &&
          coppytag &&
          plantilla.charAt(i) !== '{' &&
          plantilla.charAt(i) !== ';'
        ) {
          temEstilo = temEstilo + plantilla.charAt(i);
        }
        if (!coppyvalor && coppyEstyle && plantilla.charAt(i) !== ':') {
          estilovalor = estilovalor + plantilla.charAt(i);
        }
      }
    }

    this.lista.push({ value: 0, label: 'Selecciona  un estilo' });
    for (let i = 0; i < this.estilos.length; i++) {
      this.lista.push({ value: i + 1, label: this.estilos[i].tagEstilo });
    }
    this.setState({ Lista: this.lista });
    this.setState({ estilosArray: this.estilos });
  };

  getContenido = () => {
    this.texto = [];
    var temeEiqueta = '';
    var etiquetaban = false;
    var coppying = false;
    var startcopy = false;
    var texto = '';
    var etiq = '';
    var indice1 = 0;
    var indice2 = 0;
    var subEtiqueta = false;
    for (var i = 0; i < plantilla.length; i++) {
      if (plantilla.charAt(i) == '<') {
        coppying = true;
      }
      if (
        plantilla.charAt(i) == '<' &&
        etiquetaban &&
        plantilla.charAt(i + 1) !== '/'
      ) {
        indice1 = i;
      }

      if (coppying) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
      }
      if (plantilla.charAt(i) == '>') {
        coppying = false;
        //if(etiqueta == temeEiqueta){
        etiquetaban = true;
        if (etiquetaban) {
          if (
            temeEiqueta.toUpperCase().trim().includes('<H') &&
            temeEiqueta.toUpperCase().trim() !== '<HEADER>'
          ) {
            startcopy = true;
            etiq = temeEiqueta;
          } else {
            if (
              temeEiqueta.toUpperCase().trim().includes('</H') &&
              temeEiqueta.toUpperCase().trim() !== '</HTML>' &&
              temeEiqueta.toUpperCase().trim() !== '</HEAD>' &&
              temeEiqueta.toUpperCase().trim() !== '</HEADER>'
            ) {
              indice2 = i + 1;
              this.texto.push({
                open: etiq,
                txt: texto,
                close: temeEiqueta,
                indice1: indice1,
                indice2: indice2,
              });
              texto = '';
            }
          } //h1 a h n
          if (temeEiqueta.toUpperCase().trim().includes('<P')) {
            startcopy = true;
            etiq = temeEiqueta;
          } else {
            if (temeEiqueta.toUpperCase().trim() == '</P>') {
              indice2 = i + 1;
              this.texto.push({
                open: etiq,
                txt: texto,
                close: temeEiqueta,
                indice1: indice1,
                indice2: indice2,
              });
              texto = '';
            }
          }
        }
        temeEiqueta = '';
      }
      if (plantilla.charAt(i) == '<') {
        startcopy = false;
      }
      if (startcopy && plantilla.charAt(i) !== '>') {
        texto = texto + plantilla.charAt(i);
      }
    }
  };
  onChangePlantilla = (event) => {
    this.setState({ plantilla: event.target.value });
    plantilla = event.target.value;
    //test.innerHTML= newPlantilla;
  };
  writePlantilla = (element = '') => {
    this.setState({ plantilla: plantilla });
    var test = document.querySelector('div[name="textplantilla"]');
    let plantillaTem = plantilla;
    this.state.images.map((img, index) => {
      plantillaTem = plantillaTem.replace(
        'cid:' + img.cid,
        conf.api_raiz + 'imagenes/' + img.filename
      );
      //document.querySelector('img[name="'+img.cid+'"]').src= conf.api_raiz+"imagenes/"+img.filename;
    });
    test.innerHTML = plantillaTem;
    //test.innerHTML= plantilla;
    setTimeout(
      function () {
        this.showCampos();
      }.bind(this),
      3000
    );

    this.getContenido();
    if (this.state.texto !== this.texto) {
      this.setState({ texto: this.texto });
    }
    this.readPlantilla();
    /** */
    if (element == 'texto') {
      this.props.setPlantilla('');
    } else {
      this.props.setPlantilla(plantilla);
    }
    if (this.props.correoPlantillas) {
      this.setState({ loading: false });
    }
    let imagen = document.querySelector('div[id="imagenDemo"]');
    if (imagen) {
      imagen.innerHTML =
        '<img src="images/loadPicture.png" name="imagen" width="50%" style="border-style: solid;" />';
      //<img name="cidname" src= "images/loadPicture.png"  width="50%" align="center" border="0" />
    }
  };
  showCampos = () => {
    var addCampos = document.querySelector('tbody[id="campos"]');
    if (addCampos) {
      var Ucampos = '';
      if (this.props.correoPlantillas.vertical) {
        this.state.correoCampos.map((cam, index) => {
          if (this.isCheckedCampo(cam.id)) {
            Ucampos =
              Ucampos +
              '<tr><th>' +
              cam.campoEtiqueta +
              '</th><td>' +
              cam.campoEtiqueta +
              '</td></tr>';
          }
        });
      } else {
        Ucampos = Ucampos + '<tr>';
        this.state.correoCampos.map((cam, index) => {
          if (this.isCheckedCampo(cam.id)) {
            Ucampos = Ucampos + '<th>' + cam.campoEtiqueta + '</th>';
          }
        });
        Ucampos = Ucampos + '</tr><tr>';
        this.state.correoCampos.map((cam, index) => {
          if (this.isCheckedCampo(cam.id)) {
            Ucampos = Ucampos + '<td>' + cam.campoEtiqueta + '</td>';
          }
        });
        Ucampos = Ucampos + '</tr>';
      }
      addCampos.innerHTML = Ucampos;
    } else {
      if (this.props.texto !== '') {
        //alert("Err: No se cargaron los campos")
      }
    }
  };

  validEtiqueta = (etiqueta) => {
    var found = false;
    for (let et of this.etiquetas) {
      if (etiqueta.includes(et)) {
        found = true;
      }
    }
    if (!found) {
      return true;
    } else {
      return false;
    }
    found = false;
  };
  changeEstilo = (event) => {
    this.setState({ estilos: event });
  };
  changeTexto = (event) => {
    plantilla =
      plantilla.substr(0, this.texto[event.target.name].indice1) +
      this.texto[event.target.name].open +
      event.target.value +
      this.texto[event.target.name].close +
      plantilla.substr(this.texto[event.target.name].indice2, plantilla.length);
    this.writePlantilla('texto');
  };

  changeStyle = (event) => {
    var valor = event.target.value;
    if (
      this.estilos[this.state.estilos.value - 1].arrayEstilo[event.target.name]
        .toUpperCase()
        .trim() == 'FONT-SIZE'
    ) {
      valor = valor + 'px';
    }
    if (
      this.estilos[this.state.estilos.value - 1].arrayEstilo[event.target.name]
        .toUpperCase()
        .trim() == 'WIDTH'
    ) {
      valor = valor + '%';
    }
    if (
      this.estilos[this.state.estilos.value - 1].arrayEstilo[event.target.name]
        .toUpperCase()
        .trim() == 'HEIGHT'
    ) {
      valor = valor + '%';
    }
    if (
      this.estilos[this.state.estilos.value - 1].arrayEstilo[event.target.name]
        .toUpperCase()
        .trim()
        .includes('MARGIN')
    ) {
      valor = valor + '%';
    }
    if (event.target.value) {
      this.estilos[this.state.estilos.value - 1].arrayvalor[event.target.name] =
        valor;
      this.setState({ estilosArray: this.estilos });
      this.modifyPlantilla(event.target.name);
    }
  };
  modifyPlantilla = (index) => {
    var temeEiqueta = '';
    var coppying = false;

    var estilo = false;
    var tagEstilo = '';
    var temEstilo = '';
    var arrayEstilo = [];
    var estilovalor = '';
    var arrayvalor = [];
    var coppytag = false;
    var coppyEstyle = false;
    var coppyvalor = false;

    var indice1 = 0;
    var indice2 = 0;
    var copyindex = false;
    var remplace = false;

    for (var i = 0; i < plantilla.length; i++) {
      if (plantilla.charAt(i) === '<' && !coppying) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
        coppying = true;
      }
      if (
        plantilla.charAt(i) !== '<' &&
        plantilla.charAt(i) !== '>' &&
        coppying
      ) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
      }
      if (plantilla.charAt(i) === '>' && coppying) {
        temeEiqueta = temeEiqueta + plantilla.charAt(i);
        coppying = false;
        if (temeEiqueta == '<style>') {
          estilo = true;
        }
        if (temeEiqueta == '</style>') {
          estilo = false;
        }
        temeEiqueta = '';
      }

      if (estilo) {
        if (
          estilo &&
          plantilla.charAt(i) !== '{' &&
          estilo &&
          plantilla.charAt(i) !== '>' &&
          !coppytag
        ) {
          tagEstilo = tagEstilo + plantilla.charAt(i);
        }
        if (estilo && plantilla.charAt(i) == '{' && !coppytag) {
          //this.estilos.push({temeEiqueta:[]});
          if (
            this.estilos[this.state.estilos.value - 1].tagEstilo == tagEstilo
          ) {
          }
          coppytag = true;
          copyindex = false;
        }
        if (plantilla.charAt(i) == ':') {
          coppyEstyle = true;
          coppyvalor = false;
          //arrayEstilo.push(temEstilo);
          if (
            this.estilos[this.state.estilos.value - 1].arrayEstilo[index] ==
              temEstilo &&
            this.estilos[this.state.estilos.value - 1].tagEstilo == tagEstilo
          ) {
            copyindex = true;
            remplace = true;
          }
          temEstilo = '';
        }
        //save
        if (plantilla.charAt(i) == '}') {
          coppytag = false; // Fin
          //this.estilos.push({ tagEstilo, arrayEstilo, arrayvalor })
          arrayEstilo = [];
          arrayvalor = [];
          tagEstilo = '';
        }
        if (plantilla.charAt(i) == ';') {
          coppyEstyle = false;
          coppyvalor = true; //comparar valor
          //arrayvalor.push(estilovalor);

          if (!copyindex) {
            indice2 = i;
            copyindex = true;
          }
          if (remplace) {
            plantilla =
              plantilla.substring(0, indice1) +
              this.estilos[this.state.estilos.value - 1].arrayvalor[index] +
              plantilla.substring(indice2, plantilla.length);
            //this.setState({loading: true });
            this.writePlantilla();
            estilovalor = '';
            tagEstilo = '';
            break;
          }
          estilovalor = '';
        }

        if (
          !coppyEstyle &&
          coppytag &&
          plantilla.charAt(i) !== '{' &&
          plantilla.charAt(i) !== ';'
        ) {
          temEstilo = temEstilo + plantilla.charAt(i);
        }
        if (!coppyvalor && coppyEstyle && plantilla.charAt(i) !== ':') {
          estilovalor = estilovalor + plantilla.charAt(i);
          if (copyindex) {
            indice1 = i;
            copyindex = false;
          }
        }
      }
    }
  };

  getStyleValue = (index) => {
    var regexp = /^#([A-Fa-f0-9]{3}){1,2}$/;
    switch (
      this.estilos[this.state.estilos.value - 1].arrayEstilo[index]
        .toUpperCase()
        .trim()
    ) {
      case 'BACKGROUND-COLOR':
        if (
          regexp.test(
            this.estilos[this.state.estilos.value - 1].arrayvalor[index]
          )
        ) {
          return this.estilos[this.state.estilos.value - 1].arrayvalor[index];
        } else {
          return RgbATohex(
            this.estilos[this.state.estilos.value - 1].arrayvalor[index]
          );
        }
        break;
      case 'COLOR':
        if (
          regexp.test(
            this.estilos[this.state.estilos.value - 1].arrayvalor[index]
          )
        ) {
          return this.estilos[this.state.estilos.value - 1].arrayvalor[index];
        } else {
          return RgbATohex(
            this.estilos[this.state.estilos.value - 1].arrayvalor[index]
          );
        }
        break;
      case 'FONT-SIZE':
        return parseInt(
          this.estilos[this.state.estilos.value - 1].arrayvalor[index]
            .toUpperCase()
            .trim()
            .replace('PX', '')
        );
        break;
      case 'FLOAT':
        return this.estilos[this.state.estilos.value - 1].arrayvalor[index];
        break;
      case 'TEXT-ALIGN':
        return this.estilos[this.state.estilos.value - 1].arrayvalor[index];
        break;
      default:
        {
          let opcionTemp = this.estilos[
            this.state.estilos.value - 1
          ].arrayEstilo[index]
            .toUpperCase()
            .trim();
          if (opcionTemp.includes('MARGIN') || opcionTemp.includes('WIDTH')) {
            return parseInt(
              this.estilos[this.state.estilos.value - 1].arrayvalor[index]
                .toUpperCase()
                .trim()
                .replace('%', '')
            );
          }
        }
        break;
    }
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  changeCampo = (event) => {
    var correocampos = this.state.correoCampos
      .filter((word) =>
        this.props.correoPlantillas
          ? this.props.correoPlantillas.idAcumulador.id === 10
            ? alertasSinRuta.includes(word.campoNombre) &&
              word.campoEtiqueta
                .toUpperCase()
                .includes(this.state.search.toUpperCase())
            : word.campoEtiqueta
                .toUpperCase()
                .includes(this.state.search.toUpperCase())
          : false
      )
      .sort();

    if (this.isCheckedCampo(correocampos[event.target.value].id)) {
      if (this.props.correoPlantillas !== null) {
        this.getfuntion(
          'delete',
          '',
          correocampos[event.target.value].id +
            '/' +
            this.props.correoPlantillas.id,
          'correoColumnas',
          'correoColumnas'
        );
      }
    } else {
      if (this.props.correoPlantillas !== null) {
        this.getfuntion(
          'post',
          {
            idCorreoCampos: correocampos[event.target.value].id,
            idCorreoPlantilla: this.props.correoPlantillas.id,
          },
          '',
          'correoColumnas',
          'correoColumnas'
        );
      }
    }
  };
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  };

  fileOnload = (e) => {
    var result = e.target.result;
    //this.setState({temImage:"" })
    //var file    = document.querySelector('input[type=file]').files[0];
    //this.temImages[imageIndex].base64= "cid:"+reader.result;
    //this.temImages[imageIndex].base64= reader.result;
    //
    this.writePlantilla();
  };
  ChangeImage = (event) => {
    this.setState({ loading: true });
    imageIndex = event.target.name;
    var file = event.target.files[0];
    upImage = event.target.files[0];
    reader.onload = this.fileOnload;
    reader.readAsDataURL(file);
  };
  uploagImage = (indice) => {
    //let palabra="logo.png";
    //alert(palabra.split(".", 1))
    LoadPicture(upImage).then((response) => {
      if (response) {
        let cPimage = this.state.images[indice];
        cPimage.filename = response.fileName;
        this.getfuntion(
          'post',
          cPimage,
          '',
          'correoplantillasimages',
          'images'
        );
        //this.temImages[indice].base64= response;
        this.writePlantilla();
      } else {
        alert('Perdón, no se guardo');
      }
    });
  };
  changePlantilla = () => {
    //this.temImages=[];
    this.readPlantilla();
    this.writePlantilla();
  };
  isCheckedCampo = (id) => {
    for (let item of this.state.correoColumnas) {
      if (item) {
        if (id == item.idCorreoCampos) {
          if (
            this.props.correoPlantillas !== null &&
            item.idCorreoPlantilla == this.props.correoPlantillas.id
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  RowsCampos = (campo, indice) => {
    let showRow = true;
    for (let item of this.camposLogisticosAll) {
      if (item.nombreCampo === campo.campoNombre) {
        if (this.camposLogisticos[this.state.movimientoTipo.value]) {
          if (
            !this.camposLogisticos[this.state.movimientoTipo.value][
              campo.campoNombre
            ]
          ) {
            showRow = false;
          }
        }
      }
    }
    if (this.props.correoPlantillas) {
      switch (campo.campoNombre) {
        /** Naviera */
        case '_arribo_terminal_vacio':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_terminal_lleno':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_cliente_lleno':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_cliente_vacio':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case 'arribo_direcion_carga':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_terminal_vacio_2':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_retiro_contenedor':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_cliente_vacio':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_cliente_lleno':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_terminal_cargado':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_patio_linea':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_inicio_ruta':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_asignacion_piloto':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_despacho_predio_cliente':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_llegada_frontera':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_frontera':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_aduana':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_aduana':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_predio_vacio':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_cita_fecha_despacho':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_entrada_ciudad_portuaria':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_terminal_vacio':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        /** Terrestre */
        case '_arribo_descarga':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_terminal_cargado':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_arribo_unidad_contenedor':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_cita_carga':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_entrega_documentos':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_fecha_arribo':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_fecha_salida':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_ingreso_bodega':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_inicio_ruta':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        case '_salida_unidad_contenedor':
          {
            if (
              !this.camposLogisticos[this.state.movimientoTipo.value][
                campo.campoNombre
              ]
            ) {
              showRow = false;
            }
          }
          break;
        default:
          break;
      }
      /** Incidencias */ //_incidencia
      /*
      if(this.props.correoPlantillas.idAcumulador.id !== 1){
        switch (campo.campoNombre) {
          case "_incidencia":   { showRow= false; } break;
          case "_descripcion":  { showRow= false; } break;
          case "_comentario":   { showRow= false; } break;
          default:
            break;
        }
      }*/
      /** Eta campos */
      if (this.props.correoPlantillas.idAcumulador.id !== 5) {
        switch (campo.campoNombre) {
          case '_origen_direccion':
            {
              showRow = false;
            }
            break;
          case '_origen_latitud_longitud':
            {
              showRow = false;
            }
            break;
          case '_destino_latitud_longitud':
            {
              showRow = false;
            }
            break;
          case '_destino_direccion':
            {
              showRow = false;
            }
            break;
          case '_fecha_calculo':
            {
              showRow = false;
            }
            break;
          case '_distancia':
            {
              showRow = false;
            }
            break;
          case '_eta':
            {
              showRow = false;
            }
            break;
          case '_tiempo_string':
            {
              showRow = false;
            }
            break;
          default:
            break;
        }
      }
    } else {
      showRow = false;
    }

    if (showRow) {
      this.rowsCampos.push(
        <Table.Row>
          <Table.Col>{campo.campoEtiqueta}</Table.Col>
          <Table.Col>
            <Form.Switch
              type="Checkbox"
              name="campo"
              value={indice}
              checked={this.isCheckedCampo(campo.id)}
              onChange={this.changeCampo}
            />
          </Table.Col>
        </Table.Row>
      );
    }
  };
  changeMovimientotipo = (event) => {
    this.setState({ movimientoTipo: event });
  };

  render() {
    return (
      <div className="my-3 my-md-2">
        <Grid.Row>
          <Grid.Col lg={4}>
            <Card title="Personalizar" isClosable={false} isCollapsible={false}>
              <Card.Body>
                <Dimmer active={false} loader>
                  <Form.Group label="Estilos">
                    <Select
                      value={this.state.estilos}
                      onChange={this.changeEstilo}
                      options={this.state.Lista}
                    />
                  </Form.Group>

                  {this.state.estilos.value > 0 ? (
                    <div>
                      <List.Group>
                        {this.state.estilosArray
                          ? this.state.estilosArray[
                              this.state.estilos.value - 1
                            ].arrayEstilo.map((estilo, index) => (
                              <List.GroupItem
                                action
                                className={
                                  estilo.toUpperCase().trim() ==
                                  'BACKGROUND-COLOR'
                                    ? 'fa fa-paint-brush'
                                    : estilo.toUpperCase().trim() == 'COLOR'
                                    ? 'fa fa-font'
                                    : estilo.toUpperCase().trim() == 'FONT-SIZE'
                                    ? 'fa fa-text-height'
                                    : estilo.toUpperCase().trim() == 'WIDTH'
                                    ? 'fa fa-compress'
                                    : estilo.toUpperCase().trim() ==
                                      'TEXT-ALIGN'
                                    ? 'fa fa-align-center'
                                    : estilo.toUpperCase().trim() == 'HEIGHT'
                                    ? 'fa fa-compress'
                                    : estilo.toUpperCase().trim() == 'FLOAT'
                                    ? 'fa fa-indent'
                                    : estilo
                                        .toUpperCase()
                                        .trim()
                                        .includes('MARGIN')
                                    ? 'fa fa-file-text-o'
                                    : null
                                }
                              >
                                {' '}
                                {estilo}
                                {estilo.toUpperCase().trim() ==
                                'BACKGROUND-COLOR' ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="color"
                                      name={index}
                                      onChange={this.changeStyle}
                                      value={this.getStyleValue(index)}
                                    />
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'COLOR' ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="color"
                                      name={index}
                                      onChange={this.changeStyle}
                                      value={this.getStyleValue(index)}
                                    />
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'FONT-SIZE' ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="range"
                                      name={index}
                                      min="1"
                                      max="100"
                                      value={this.getStyleValue(index)}
                                      onChange={this.changeStyle}
                                    ></input>
                                    <span className={'float-right'}>
                                      {this.getStyleValue(index)} px
                                    </span>
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'WIDTH' ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="range"
                                      name={index}
                                      min="1"
                                      max="100"
                                      value={this.getStyleValue(index)}
                                      onChange={this.changeStyle}
                                    ></input>
                                    <span className={'float-right'}>
                                      {this.getStyleValue(index)} %
                                    </span>
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'HEIGHT' ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="range"
                                      name={index}
                                      min="1"
                                      max="100"
                                      value={this.getStyleValue(index)}
                                      onChange={this.changeStyle}
                                    ></input>
                                    <span className={'float-right'}>
                                      {this.getStyleValue(index)} %
                                    </span>
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'FLOAT' ? (
                                  <span className={'float-right'}>
                                    <Form.SelectGroup>
                                      <Form.SelectGroupItem
                                        icon="align-left"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'LEFT'
                                        }
                                        name={index}
                                        value="left"
                                        onClick={this.changeStyle}
                                      />

                                      <Form.SelectGroupItem
                                        icon="align-center"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'CENTER'
                                        }
                                        name={index}
                                        value="center"
                                        onClick={this.changeStyle}
                                      />
                                      <Form.SelectGroupItem
                                        icon="align-right"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'RIGHT'
                                        }
                                        name={index}
                                        value="right"
                                        onClick={this.changeStyle}
                                      />
                                    </Form.SelectGroup>
                                  </span>
                                ) : null}
                                {estilo
                                  .toUpperCase()
                                  .trim()
                                  .includes('MARGIN') ? (
                                  <span className={'float-right'}>
                                    <input
                                      type="range"
                                      name={index}
                                      min="1"
                                      max="10"
                                      value={this.getStyleValue(index)}
                                      onChange={this.changeStyle}
                                    ></input>
                                    <span className={'float-right'}>
                                      {this.getStyleValue(index)}%
                                    </span>
                                  </span>
                                ) : null}
                                {estilo.toUpperCase().trim() == 'TEXT-ALIGN' ? (
                                  <span className={'float-right'}>
                                    <Form.SelectGroup>
                                      <Form.SelectGroupItem
                                        icon="align-left"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'LEFT'
                                        }
                                        name={index}
                                        value="left"
                                        onClick={this.changeStyle}
                                      />
                                      <Form.SelectGroupItem
                                        icon="align-center"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'CENTER'
                                        }
                                        name={index}
                                        value="center"
                                        onClick={this.changeStyle}
                                      />
                                      <Form.SelectGroupItem
                                        icon="align-right"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'RIGHT'
                                        }
                                        name={index}
                                        value="right"
                                        onClick={this.changeStyle}
                                      />
                                      <Form.SelectGroupItem
                                        icon="align-justify"
                                        checked={
                                          this.getStyleValue(index)
                                            .toUpperCase()
                                            .trim() == 'JUSTIFY'
                                        }
                                        name={index}
                                        value="right"
                                        onClick={this.changeStyle}
                                      />
                                    </Form.SelectGroup>
                                  </span>
                                ) : null}
                              </List.GroupItem>
                            ))
                          : null}
                      </List.Group>
                    </div>
                  ) : null}
                  <Card
                    title="Texto"
                    isClosable={false}
                    isCollapsible
                    className={'mt-3'}
                    options={<div>No options</div>}
                    isCollapsed={true}
                  >
                    <Card.Body>
                      <List.Group>
                        {this.state.texto.map((t, index) => (
                          <List.GroupItem>
                            {t.open.toUpperCase().trim().includes('<H') ? (
                              t.txt.length < 40 ? (
                                <Form.Input
                                  name={index}
                                  onChange={this.changeTexto}
                                  placeholder="Text..."
                                  value={t.txt}
                                />
                              ) : (
                                <Form.Textarea
                                  value={t.txt}
                                  name={index}
                                  placeholder="Contenido.."
                                  rows={2}
                                  onChange={this.changeTexto}
                                />
                              )
                            ) : t.open.toUpperCase().trim().includes('<P') ? (
                              t.txt.length < 40 ? (
                                <Form.Input
                                  name={index}
                                  onChange={this.changeTexto}
                                  placeholder="Text..."
                                  value={t.txt}
                                />
                              ) : (
                                <Form.Textarea
                                  value={t.txt}
                                  name={index}
                                  placeholder="Contenido.."
                                  rows={
                                    t.txt.length >= 40 && t.txt.length < 60
                                      ? 2
                                      : t.txt.length > 60 && t.txt.length < 120
                                      ? 4
                                      : 6
                                  }
                                  onChange={this.changeTexto}
                                />
                              )
                            ) : null}
                          </List.GroupItem>
                        ))}
                      </List.Group>
                    </Card.Body>
                  </Card>
                  <Card
                    title="Logos"
                    isClosable={false}
                    isCollapsible
                    className={'mt-3'}
                    isCollapsed={true}
                    options={
                      <ModalLayout
                        tamanio={'40%'}
                        title="Agregar estructura de imagenes"
                        name={
                          <span className="badge float-right">
                            <Button pill size="sm" outline color="primary">
                              <Icon name="plus" />
                            </Button>
                          </span>
                        }
                        hiddenModal={this.state.hiddenModals}
                        Fclose={this.hiddenmodal}
                        formulario={
                          <div>
                            <h2>Precaución</h2>
                            <p>
                              Para completar este formulario debe leer el codigo
                              fuente de la plantilla e identificar ( &lt;img
                              src="cid:imageheader" ), aqui es donde se
                              insertaran las imagenes identificar la palabra
                              delante de cid: , este sera nuestro cid, y cargar
                              una imagen.
                            </p>
                            <div className="row">
                              <div className="col-6">
                                <img
                                  name="cidname"
                                  src="images/loadPicture.png"
                                  width="50%"
                                  align="center"
                                  border="0"
                                />
                              </div>
                              <div className="col-6">
                                <div className="row">
                                  <div className="col-12">
                                    <Form.Group label="Cid">
                                      <Form.Input
                                        type="text"
                                        name="cidnameinput"
                                        feedback="..."
                                        placeholder="CidNombre ..."
                                        valid={this.checkCidname()}
                                        onChange={this.changeCidname}
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="col-12">
                                    <Button
                                      target="_blank"
                                      size="sm"
                                      color="primary"
                                      icon={'image'}
                                      className="text-white float-right mt-5"
                                    >
                                      {this.state.cidViewfilename}
                                      <input
                                        type="file"
                                        id="fileUp"
                                        name="imageUp"
                                        className="file"
                                        accept="image/*"
                                        onChange={this.ChangeImageNew}
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clas-row">
                              <Button
                                size="sm"
                                color="primary"
                                icon="upload-cloud"
                                className="text-white float-right mt-8"
                                onClick={() => this.uploagImageNew()}
                              ></Button>
                            </div>
                          </div>
                        }
                      />
                    }
                  >
                  <Card.Body>
                    <List.Group>
                      {this.state.images.map((im, index) => (
                        <List.GroupItem>
                          <div className="row">
                            <div className="col-6">
                              <img
                                name={im.cid}
                                src={
                                  conf.api_raiz + 'imagenes/' + im.filename
                                }
                                width="40%"
                                align="center"
                                border="0"
                              />
                            </div>
                            <div className="col-4">
                              <Button
                                target="_blank"
                                size="sm"
                                color="primary"
                                icon={'image'}
                                className="text-white float-right mt-8"
                              >
                                {im.filename}
                                <input
                                  type="file"
                                  id="file"
                                  name={index}
                                  className="file"
                                  accept="image/*"
                                  onChange={this.ChangeImage}
                                />
                              </Button>
                            </div>
                            <div className="col-2">
                              <Button
                                size="sm"
                                color="primary"
                                icon="upload-cloud"
                                className="text-white float-right mt-8"
                                onClick={() => this.uploagImage(index)}
                              ></Button>
                            </div>
                          </div>
                        </List.GroupItem>
                      ))}
                    </List.Group>
                  </Card.Body>
                  </Card>
                  <Card
                    title="Campos"
                    isClosable={false}
                    isCollapsible
                    className={'mt-3'}
                    isCollapsed={true}
                    options={
                      <div>
                        <Form.Input
                          className="mb-3"
                          icon="search"
                          placeholder="Buscar Campo..."
                          position="append"
                          onChange={this.searching}
                        />
                        <div className="mt-3">
                          <Form.Group label="Tipo de movimiento">
                            <Select
                              value={this.state.movimientoTipo}
                              onChange={this.changeMovimientotipo}
                              options={this.state.movimientoTipos}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    }
                  >
                    <Card.Body>
                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter"
                        headerItems={[
                          { content: 'Campo' },
                          { content: 'Agregar' },
                        ]}
                      >
                        <Table.Body>
                        {
                            ((this.rowsCampos = []),
                            this.state.correoCampos
                              .filter((word) =>
                                this.props.correoPlantillas
                                  ? this.props.correoPlantillas.idAcumulador
                                      .id === 10
                                    ? alertasSinRuta.includes(
                                        word.campoNombre
                                      ) &&
                                      word.campoEtiqueta
                                        .toUpperCase()
                                        .includes(
                                          this.state.search.toUpperCase()
                                        )
                                    : word.campoEtiqueta
                                        .toUpperCase()
                                        .includes(
                                          this.state.search.toUpperCase()
                                        )
                                  : false
                              )
                              .sort()
                              .map((c, index) => {
                                this.RowsCampos(c, index);
                              }),
                            this.rowsCampos)
                          }
                        </Table.Body>
                      </Table>
                    </Card.Body>                    
                  </Card>

                  <Form.Group
                    label={
                      <Form.Label aside={plantilla.length + '/5000'}>
                        HTML{' '}
                      </Form.Label>
                    }
                  >
                    <Form.Textarea
                      value={this.state.plantilla}
                      name="showHtml"
                      placeholder="Contenido.."
                      rows={18}
                      onChange={this.onChangePlantilla}
                    />
                  </Form.Group>
                  <Button
                    className={'float-right'}
                    color="primary"
                    size="sm"
                    onClick={this.changePlantilla}
                    disabled={false}
                    icon={'refresh-cw'}
                  ></Button>
                </Dimmer>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col lg={8}>
            <Card title="Vista previa" isClosable={false} isCollapsible={false}>
              <Card.Body>
                <Dimmer active={this.state.loading} loader>
                  <Template texto={plantilla} />
                </Dimmer>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    );
  }
  changeCidname = (event) => this.setState({ cidname: event.target.value });
  checkCidname = () => {
    if (this.state.cidname.length >= 0 && this.state.cidname.length < 3) {
      return false;
    } else {
      return true;
    }
  };
  ChangeImageNew = (event) => {
    imageIndex = event.target.name;
    upImage = event.target.files[0];
    reader.onload = this.fileOnloadNew;
    reader.readAsDataURL(upImage);
    this.setState({ cidViewfilename: event.target.files[0].name });
  };
  uploagImageNew = () => {
    LoadPicture(upImage).then((response) => {
      if (response) {
        let cPimage = {
          idCorreoPlantilla: this.props.correoPlantillas.id,
          cid: this.state.cidname,
          filename: response.fileName,
        };
        this.writePlantilla();
        this.getfuntion(
          'post',
          cPimage,
          '',
          'correoplantillasimages',
          'imageNew'
        );
      } else {
        alert('Perdón, no se guardo');
      }
    });
  };
  fileOnloadNew = (e) => {
    document.querySelector('img[name="cidname"]').src = reader.result;
    //this.writePlantilla();
  };
}
const alertasSinRuta = [
  '__unidad',
  '__coordenadas',
  '__creacion',
  '__causa',
  '__usuario',
  '__atencion',
  '__transportista',
  '__placa',
  '__alertaTipo',
  '__incidencia',
  '__operador',
  '__op_medio',
  '__responsable',
  '__incidencia_fecha',
  '__problema',
  '__solucion',
  '__observaciones',
];
var plantilla = '';

export default Generador;
